import React, {useEffect, useState} from "react";
import APIClient, {Integrate} from "../../../api";
import {wait} from "../../../utils/waiter";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalContent,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure
} from "@nextui-org/react";
import dayjs from "dayjs";
import classNames from "classnames";
import Emoji from "../../../components/Emoji";
import {HiOutlineIdentification, HiOutlineKey} from "react-icons/hi";

function NewIntegrate({onClose}: { onClose: () => void }) {
  const [name, setName] = useState('');
  const [key, setKey] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  async function handleCreate() {
    const waiter = wait();
    try {
      setIsLoading(true);
      await APIClient.integrate.upsert({name, key, id: ''});
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  return (
    <Card>
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">创建应用集成</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <Emoji size={36} icon=":wrench:"/>
          <Input size="sm" label="名称" endContent={<HiOutlineIdentification size={22}/>}
                 value={name} onValueChange={setName}
          />
        </div>
        <Spacer y={8}/>
        <Input size="sm" label="密钥" endContent={<HiOutlineKey size={22}/>}
               value={key} onValueChange={setKey}
        />
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleCreate}>
          创建{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  )
}

export default function IntegrateList() {
  const [integrates, setIntegrates] = useState<Integrate[]>([]);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    (async () => {
      const integrates = (await APIClient.integrate.list())
        .map(it => ({...it, isDeleting: false}));
      setIntegrates(integrates);
    })()
  }, [timestamp]);

  const columns = [
    {key: "name", name: "应用名称",},
    {key: "key", name: "应用凭证",},
    {key: "createAt", name: "创建时间",},
    {key: "updateAt", name: "更新时间",},
    {key: "actions", name: "操作",}
  ]

  async function handleDelete(id: string) {
    const waiter = wait();
    try {
      setIntegrates(integrates.map(it => ({...it, isDeleting: it.id === id})));
      await APIClient.integrate.del(id);
      await waiter;
    } finally {
      setIntegrates(integrates.map(it => ({...it, isDeleting: false})));
      await waiter
      setTimestamp(new Date().getTime());
    }
  }

  const renderCell = (item: Integrate, columnKey: React.Key) => {
    switch (columnKey) {
      case "name":
        return (<TableCell><p className="truncate">{item.name}</p></TableCell>)
      case "key":
        return (<TableCell><p className="truncate">{item.key}</p></TableCell>)
      case "createAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(item.createAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "updateAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(item.updateAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "actions":
        return (
          <TableCell className="w-[180px]">
            <div className="flex justify-center space-x-2">
              <div>
                <Button isLoading={item["isDeleting"]}
                        variant="ghost" size="sm" color="danger"
                        onClick={() => handleDelete(item.id)}>删除</Button>
              </div>
            </div>
          </TableCell>
        )
      default:
        return (<TableCell>...</TableCell>)
    }
  };
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (<>
      <Modal placement="center" size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => <NewIntegrate onClose={() => {
            onClose();
            setTimestamp(new Date().getTime());
          }}/>}
        </ModalContent>
      </Modal>
      <Card>
        <CardHeader className="px-6 flex justify-between h-16">
          <div/>
          <div className="flex items-center space-x-2">
            <Button variant="ghost" color="primary" size="sm"
                    onClick={() => onOpen()}
            >新增</Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table shadow="none" aria-label="集成应用列表">
            <TableHeader columns={columns}>
              {(column) => (
                <TableColumn
                  key={column.key}
                  className={classNames(
                    (column.key === "name" || column.key === "key") ? "" : "text-center"
                  )}
                >{column.name}</TableColumn>
              )}
            </TableHeader>
            <TableBody items={integrates}>
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => renderCell(item, columnKey)}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
