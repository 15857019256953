import {useList} from "react-use";
import APIClient, {Memory, MemoryUpsert} from "../../api";
import React, {useEffect, useState} from "react";
import {wait} from "../../utils/waiter";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalContent,
  useDisclosure
} from "@nextui-org/react";
import {FaPlus} from "react-icons/fa";
import {HiOutlineIdentification} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import Emoji from "../../components/Emoji";
import {SlOptions} from "react-icons/sl";

function MemoryBlock({memory, onDelete, onEdit}: { memory: Memory, onDelete: () => void, onEdit: () => void }) {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    const waiter = wait();
    try {
      setIsDeleting(true);
      await APIClient.memory.del(memory.id)
      await waiter;
      onDelete();
    } finally {
      await waiter;
      setIsDeleting(false)
    }
  }

  return (
    <Card shadow="sm" className="w-full h-48">
      <CardHeader className="p-4 flex justify-between">
        <Emoji icon={memory.icon}/>
        <div className="text-sm font-bold">{memory.name}</div>
        <div
          className="cursor-pointer p-2 rounded-3xl hover:shadow"
          onClick={onEdit}>
          <SlOptions/>
        </div>
      </CardHeader>
      <CardBody className="p-4"></CardBody>
      <CardFooter className="space-x-2 flex justify-end">
        <Button variant="ghost" color="primary" size="sm"
                onClick={() => navigate(`/console/memory/${memory.id}/documents`)}>管理</Button>
        <Button variant="ghost" isLoading={isDeleting} color="danger" size="sm" onClick={handleDelete}>删除</Button>
      </CardFooter>
    </Card>
  )
}

function NewMemory({memory, onClose}: { memory: MemoryUpsert | undefined, onClose: () => void }) {
  const [icon, setIcon] = useState(memory?.icon ?? ':book:');
  const [name, setName] = useState(memory?.name ?? '');

  const [isLoading, setIsLoading] = useState(false);

  async function handleCreate() {
    const waiter = wait();
    try {
      setIsLoading(true);
      await APIClient.memory.upsert({name, icon, id: memory?.id ?? ''});
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false)
      onClose();
    }
  }

  const action = memory?.id ? '修改' : '创建';

  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{action}记忆体</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <Emoji size={36} picker icon={icon} onIconChanged={setIcon}/>
          <Input size="sm" label="名称" endContent={<HiOutlineIdentification size={22}/>}
                 value={name} onValueChange={setName}
          />
        </div>
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleCreate}>
          {action}{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  )
}

export default function MemoryList() {
  const [memories, setMemories] = useList<Memory>();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    (async () => {
      const memories = await APIClient.memory.list();
      setMemories.set(memories)
    })()
  }, [setMemories, timestamp]);

  const [currentMemory, setCurrentMemory] = useState<MemoryUpsert | undefined>(undefined)
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return <>
    <Modal placement="center" size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => <NewMemory memory={currentMemory} onClose={() => {
          onClose();
          setTimestamp(new Date().getTime());
        }}/>}
      </ModalContent>
    </Modal>
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <Card className="w-full h-48 flex justify-center items-center animate__animated animate__fadeIn"
            isPressable isHoverable
            shadow="sm"
            onClick={() => {
              setCurrentMemory(undefined);
              onOpen();
            }}>
        <FaPlus size={32}/>
      </Card>
      {
        memories.map((m, index) => (
          <div key={m.id}
               className="animate__animated animate__fadeIn animate__faster"
               style={{animationDelay: `${index * 50}ms`}}>
            <MemoryBlock memory={m} onDelete={() => setTimestamp(new Date().getTime())} onEdit={() => {
              setCurrentMemory(m);
              onOpen();
            }}/>
          </div>
        ))
      }
    </div>
  </>
}
