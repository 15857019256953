export class APIError extends Error {
  public code: number;
  public data?: any;

  constructor(code: number, message: string, data?: any) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

export class APICommonError extends APIError {
  constructor(message = "未知错误") {
    super(-2, message);
    this.name = "通用错误"
  }
}

export class APIAuthenticationError extends APIError {
  constructor() {
    super(-3, "登录失效, 请重新登录");
    this.name = "登录错误"
  }
}

export class APIAccessDeniedError extends APIError {
  constructor() {
    super(-4, "权限不足");
    this.name = "权限错误"
  }
}

export class APILogicError extends APIError {
  constructor(code: number, message: string, data?: any) {
    super(code, message, data);
    this.name = "逻辑错误"
  }
}
