import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {BsRobot} from "react-icons/bs";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger} from "@nextui-org/react";
import React from "react";
import {FaRegUser} from "react-icons/fa";
import {setToken} from "../hooks/useToken";
import {useUser} from "../hooks/useUser";
import {LuBrainCircuit} from "react-icons/lu";
import {VscTools} from "react-icons/vsc";
import {GrMemory} from "react-icons/gr";
import classNames from "classnames";

export default function Layout() {
  const [user] = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  function handleLogout() {
    setToken(undefined);
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="h-14 px-10 shadow flex items-center">
        <Link to="/console" className="animate__animated animate__fadeIn">
          <div className="flex items-center">
            <BsRobot size={32}/>
            <div className="ml-2 text-large font-bold">Jarvis</div>
          </div>
        </Link>
        <div className={
          classNames(
            "h-full flex-1 flex justify-center items-center relative",
            "*:mx-6 *:text-[14px] *:cursor-pointer *:flex *:items-center"
          )
        }>
          <div className="animate__animated animate__fadeInDown *:mx-1"
               onClick={() => navigate("/console/agent")}>
            <div className={location.pathname.startsWith("/console/agent") ? "text-primary" : ""}>
              <LuBrainCircuit size={18}/>
            </div>
            <div className={location.pathname.startsWith("/console/agent") ? "font-bold text-primary" : ""}>
              智能体
            </div>
          </div>
          <div className="animate__animated animate__fadeInDown *:mx-1"
               style={{animationDelay: '100ms'}}
               onClick={() => navigate("/console/memory")}>
            <div className={location.pathname.startsWith("/console/memory") ? "text-secondary" : ""}>
              <GrMemory size={18}/>
            </div>
            <div className={location.pathname.startsWith("/console/memory") ? "font-bold text-secondary" : ""}>
              记忆体
            </div>
          </div>
          <div className="animate__animated animate__fadeInDown *:mx-1"
               style={{animationDelay: '200ms'}}
               onClick={() => navigate("/console/action")}>
            <div className={location.pathname.startsWith("/console/action") ? "text-warning" : ""}>
              <VscTools size={18}/>
            </div>
            <div className={location.pathname.startsWith("/console/action") ? "font-bold text-warning" : ""}>
              行动体
            </div>
          </div>
          <div className={classNames(
            "absolute bottom-0",
            "transition-transform duration-500 ease-in-out",
            location.pathname.startsWith("/console/agent")
              ? "translate-x-[-122px] *:bg-primary"
              : (
                location.pathname.startsWith("/console/memory")
                  ? "translate-x-[0px] *:bg-secondary"
                  : "translate-x-[128px] *:bg-warning"
              )
          )}>
            <div className="h-1 w-12 animate__animated animate__fadeInUp"/>
          </div>
        </div>
        <div className="animate__animated animate__fadeIn">
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" radius="full" startContent={<FaRegUser size={20}/>}>
                {user?.name}
              </Button>
            </DropdownTrigger>
            <DropdownMenu variant="flat">
              <DropdownItem onClick={handleLogout} className="text-center">注 销</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto px-10 py-8">
        <Outlet/>
      </div>
    </div>
  )
}
