import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import APIClient, {Agent, Memory} from "../../api";
import Loading from "../../components/Loading";
import {wait} from "../../utils/waiter";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
  SelectItem,
  Spacer,
  Textarea,
  useDisclosure
} from "@nextui-org/react";
import {MdOutlineDescription, MdOutlineShare, MdPublish} from "react-icons/md";
import {useList} from "react-use";
import {GrMemory} from "react-icons/gr";
import {VscDebugAlt, VscSymbolNamespace} from "react-icons/vsc";
import {HiOutlineIdentification} from "react-icons/hi";
import InstructionsEditor from "../../components/InstructionsEditor";
import Chat from "../../components/Chat";
import {FaInfinity} from "react-icons/fa6";
import Emoji from "../../components/Emoji";
import AgentShare from "../../components/AgentShare";

export default function AgentEditor() {
  const params = useParams();
  const agentId = params.agentId!;
  const [timestamp, setTimestamp] = useState(new Date().getTime());

  async function handleSubmit(agent: Agent) {
    const waiter = wait();
    await APIClient.agent.upsert(agent);
    await waiter;
    setTimestamp(new Date().getTime())
  }

  return (
    <div className="h-full flex *:flex-1 space-x-4">
      <div className="h-full">
        <AgentSetting agentId={agentId} onSubmit={handleSubmit}/>
      </div>
      <div className="h-full">
        <AgentDebug agentId={agentId} timestamp={timestamp}/>
      </div>
    </div>
  )
}

function AgentDebug({agentId, timestamp}: { agentId: string, timestamp: number }) {
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  useEffect(() => {
    (async () => {
      const waiter = wait();
      const agent = await APIClient.agent.get(agentId);
      await waiter;
      setAgent(agent);
    })()
  }, [agentId, timestamp]);

  const [agents, setAgents] = useState<Agent[]>([]);
  useEffect(() => {
    (async () => {
      const agents = await APIClient.agent.list();
      setAgents(agents)
    })()
  }, []);

  //加载中
  if (typeof agent === "undefined")
    return <Loading/>;

  return (
    <Card shadow="none" className="h-full w-full flex flex-col bg-gray-50">
      <CardHeader className="px-5 flex justify-between">
        <div className="flex items-center space-x-2">
          <VscDebugAlt size={20}/>
          <div className="text-sm font-bold">调试</div>
        </div>
        <div className="flex items-center space-x-2">
          <div className="text-[12px] font-bold">嵌入到其他智能体中调试</div>
          <div className="w-40">
            <Select size="sm" labelPlacement="outside-left" placeholder="选择智能体" aria-label="选择智能体">
              {
                agents
                  .filter(a => a.id !== agentId)
                  .map(a => <SelectItem key={a.id} value={a.id}>{a.title}</SelectItem>)
              }
            </Select>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="bg-white h-full rounded-xl p-4">
          <Chat key={timestamp} agentId={agentId}/>
        </div>
      </CardBody>
    </Card>
  )
}

function AgentSetting({agentId, onSubmit}: {
  agentId: string,
  onSubmit: (agent: Agent) => Promise<void>
}) {
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  useEffect(() => {
    (async () => {
      const waiter = wait();
      const agent = await APIClient.agent.get(agentId);
      await waiter;
      setAgent(agent);
    })()
  }, [agentId]);

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  //加载中
  if (typeof agent === "undefined")
    return <Loading/>;

  return (
    <Card className="h-full w-full flex flex-col">
      <CardHeader className="px-5 flex h-16">
        <div className="flex items-center space-x-4">
          <Emoji picker size={38} icon={agent.icon} onIconChanged={icon => setAgent({...agent, icon})}/>
          <div>
            <div className="text-sm font-bold">{agent.title}</div>
            <div
              className="text-[12px] max-w-48 whitespace-nowrap overflow-ellipsis overflow-hidden">{agent.description}</div>
          </div>
        </div>
        <div className="flex-1 flex justify-center">
          {agent.name &&
            <Chip variant="dot" color="primary" size="sm">{agent.name}</Chip>
          }
        </div>
        <div className="flex items-center space-x-2">
          <Modal placement="center" size="sm" hideCloseButton
                 isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
              {() => (
                <>
                  <ModalHeader>
                    分享智能体
                  </ModalHeader>
                  <ModalBody className="pb-6">
                    <AgentShare agentId={agent.id}/>
                  </ModalBody>
                </>
              )}
            </ModalContent>
          </Modal>
          <Button endContent={<MdOutlineShare size={18}/>}
                  variant="ghost" color="success" size="sm"
                  onClick={onOpen}>
            分享
          </Button>
          <Button isLoading={isSubmitting}
                  endContent={<MdPublish size={18}/>}
                  variant="ghost" color="primary" size="sm"
                  onClick={async () => {
                    try {
                      setIsSubmitting(true)
                      await onSubmit(agent)
                    } finally {
                      setIsSubmitting(false)
                    }
                  }}>
            发布
          </Button>
        </div>
      </CardHeader>
      <CardBody className="flex flex-col overflow-hidden">
        <div className="rounded-xl bg-gray-50 p-3">
          <div className="flex items-center space-x-6">
            <div className="flex items-center space-x-2">
              <GrMemory className="text-secondary" size={16}/>
              <div className="text-sm font-bold">记忆体</div>
            </div>
            <div className="flex-1">
              <MemorySelect providers={agent.memoryProviders}
                            onProvidersChanged={(ids) => setAgent({
                              ...agent,
                              memoryProviders: ids
                            })}
              />
            </div>
            <Checkbox size="sm" color="secondary"
                      isSelected={agent.baseOnCtx}
                      onValueChange={v => setAgent({...agent, baseOnCtx: v})}
            >
              基于上下文提取
            </Checkbox>
          </div>
        </div>
        <Spacer y={3}/>
        <div className="flex-1 overflow-hidden min-h-28">
          <InstructionsEditor agentId={agent.id} instructions={agent.instructions}
                              onInstructionsChanged={v => setAgent({...agent, instructions: v})}
          />
        </div>
        <Spacer y={3}/>
        <div className="rounded-xl bg-gray-50 p-3 overflow-y-auto">
          <BaseSetting agent={agent} onAgentChanged={setAgent}/>
        </div>
      </CardBody>
    </Card>
  )
}

function BaseSetting({agent, onAgentChanged}: { agent: Agent, onAgentChanged: (agent: Agent) => void }) {
  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const models = await APIClient.agent.models();
      setModels(models)
    })()
  }, []);
  const selectedModel = useMemo(() =>
      models.some(m => m === agent.model)
        ? [agent.model]
        : [],
    [models, agent],
  );
  return (
    <>
      <div className="flex *:flex-1 space-x-4">
        <Input size="sm" label="调用名" labelPlacement="outside"
               endContent={<VscSymbolNamespace className="text-primary" size={22}/>}
               value={agent.name}
               onValueChange={(v) => onAgentChanged({...agent, name: v})}
        />
        <Input size="sm" label="友好名称" labelPlacement="outside"
               endContent={<HiOutlineIdentification className="text-amber-500" size={22}/>}
               value={agent.title}
               onValueChange={(v) => onAgentChanged({...agent, title: v})}
        />
      </div>
      <Spacer y={2}/>
      <Select size="sm" label="模型" labelPlacement="outside" placeholder="请选择LLM模型"
              endContent={<FaInfinity className="text-secondary" size={22}/>}
              selectedKeys={selectedModel}
              onSelectionChange={(selection) => {
                selection = selection as Set<string>
                const selected = Array.from(selection.values()).map(it => it.toString());
                if (selected.length > 0)
                  onAgentChanged({
                    ...agent,
                    model: selected[0]
                  })
              }}
      >
        {models.map(m => <SelectItem key={m} value={m}>{m}</SelectItem>)}
      </Select>
      <Spacer y={2}/>
      <div className="p-3 rounded-lg bg-gray-100">
        <div className="text-tiny mb-2">选项</div>
        <div className="grid grid-cols-2 gap-y-2">
          <Checkbox size="sm" color="secondary"
                    isSelected={agent.presentationMemory}
                    onValueChange={v => onAgentChanged({...agent, presentationMemory: v})}
          >
            自动注入演讲记忆体
          </Checkbox>
          <Checkbox size="sm" color="secondary"
                    isSelected={agent.prologue}
                    onValueChange={v => onAgentChanged({...agent, prologue: v})}
          >
            自动化开场白
          </Checkbox>
          <Checkbox size="sm" color="secondary"
                    isSelected={agent.textAudioSync}
                    onValueChange={v => onAgentChanged({...agent, textAudioSync: v})}
          >
            文本/音频同步输出
          </Checkbox>
        </div>
      </div>
      <Spacer y={2}/>
      <Textarea size="sm" label="描述" placeholder="一个很有用的智能体"
                endContent={<MdOutlineDescription className="text-cyan-600" size={22}/>}
                minRows={2} maxRows={3}
                value={agent.description}
                onValueChange={(v) => onAgentChanged({...agent, description: v})}
      />
    </>
  )
}

function MemorySelect({providers, onProvidersChanged}: {
  providers: string[],
  onProvidersChanged: (ids: string[]) => void
}) {
  const [memories, setMemories] = useList<Memory>();
  useEffect(() => {
    (async () => {
      const memories = await APIClient.memory.list();
      setMemories.set(memories);
    })()
  }, [setMemories]);
  const selectedMemoryProviders = useMemo(() => providers.filter(id => memories.some(m => m.id === id)), [providers, memories]);
  return (
    <Select labelPlacement="outside-left" placeholder="请选择嵌入的记忆体"
            items={memories} selectionMode="multiple" isMultiline aria-label="请选择嵌入的记忆体"
            renderValue={
              selected =>
                selected.map(it =>
                  <Chip key={it.key} variant="flat" className="m-1" size="sm" color="secondary">{it.textValue}</Chip>
                )
            }
            selectedKeys={selectedMemoryProviders}
            onSelectionChange={(selection) => {
              selection = selection as Set<string>
              onProvidersChanged(Array.from(selection.values()).map(it => it.toString()))
            }}
    >
      {memory => (
        <SelectItem key={memory.id} value={memory.id} textValue={memory.name}>
          <div className="flex items-center space-x-4 h-6">
            <div className="text-secondary"><GrMemory/></div>
            <div>{memory.name}</div>
          </div>
        </SelectItem>
      )}
    </Select>
  )
}

