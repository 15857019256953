import {useEffect, useMemo, useRef} from "react";
import {markdownRender} from "../utils/markdown";
import classNames from "classnames";
import styles from "./Markdown.module.scss"
import {useDebounce} from "ahooks";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

export default function Markdown({content = ""}: { content?: string }) {
  const markdownElementRef = useRef<HTMLDivElement>(null);
  const markdownContent = useMemo(() => markdownRender(content), [content]);
  const debounceContent = useDebounce(markdownContent, {wait: 500, maxWait: 1000});

  useEffect(() => {
    const element = markdownElementRef.current;
    if (element) {
      const instance = new Viewer(element, {
        loading: false, tooltip: false, toolbar: false,
        title: false, navbar: false,
      });
      return () => {
        instance.destroy();
      }
    }
  }, [debounceContent]);

  return (
    <div ref={markdownElementRef}
         className={classNames(styles.markdownContainer, "prose-sm")}
         dangerouslySetInnerHTML={{__html: markdownContent}}/>
  )
}
