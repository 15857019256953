import {useParams} from "react-router-dom";
import {setToken} from "../hooks/useToken";
import {useEffect, useState} from "react";
import APIClient, {User} from "../api";
import Loading from "../components/Loading";
import {toast} from "react-hot-toast";
import ChatComponent from "../components/Chat";
import classNames from "classnames";

export default function Chat() {
  //获取参数
  const params = useParams();
  const authenticate = params["authenticate"];
  const agentId = params["agentId"];
  //初始化
  const [user, setUser] = useState<User>();
  useEffect(() => {
    (async () => {
      try {
        //尝试登陆
        const token = await APIClient.user.login("", "", authenticate!);
        setToken(token);
        //获取用户信息
        const user = await APIClient.user.current();
        setUser(user);
      } catch (e) {
        toast.error("链接已经失效,请检查");
      }
    })()
    //获取用户
  }, [authenticate]);

  //等待用户加载
  if (typeof user === "undefined")
    return <div className="h-full w-full"><Loading/></div>

  return (
    <div className="h-full w-full flex justify-center">
      <div className={classNames(
        "h-full flex flex-col items-center",
        "w-full sm:w-full md:w-[750px] lg:w-[900px]",
        "*:w-full",
        "pt-2 pb-6 px-6 space-y-2",
        "sm:p-6 sm:space-y-6"
      )}>
        <div className="text-center uppercase text-3xl font-bold">
          Jarvis
        </div>
        <div className="flex-1 overflow-hidden">
          <ChatComponent agentId={agentId!}/>
        </div>
      </div>
    </div>
  )
}
