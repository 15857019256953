import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalContent,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
  useDisclosure
} from "@nextui-org/react";
import APIClient, {Openapi, OpenapiUpsert} from "../../../api";
import React, {useEffect, useState} from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import {wait} from "../../../utils/waiter";
import {HiOutlineIdentification} from "react-icons/hi";
import Emoji from "../../../components/Emoji";
import {MdOutlineDescription} from "react-icons/md";

function NewOpenapi({openapi, onClose}: { openapi: OpenapiUpsert | undefined, onClose: () => void }) {
  const [name, setName] = useState(openapi?.name ?? '');
  const [config, setConfig] = useState(openapi?.config ?? '');

  const [isLoading, setIsLoading] = useState(false);

  async function handleCreate() {
    const waiter = wait();
    try {
      setIsLoading(true);
      await APIClient.openapi.upsert({name, config, id: openapi?.id ?? ''});
      await waiter;
    } finally {
      await waiter;
      setIsLoading(false);
      onClose();
    }
  }

  const action = openapi?.id ? '修改' : '创建';


  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">{action} OpenAPI</div>
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <Emoji size={36} icon=":nut_and_bolt:"/>
          <Input size="sm" label="名称" endContent={<HiOutlineIdentification size={22}/>}
                 value={name} onValueChange={setName}
          />
        </div>
        <Spacer y={8}/>
        <Textarea label="OpenAPI 地址" placeholder="OpenAPI地址或者内容"
                  endContent={<MdOutlineDescription size={22}/>}
                  minRows={2}
                  value={config} onValueChange={setConfig}
        />
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleCreate}>
          {action}{isLoading ? '中' : ''}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  )
}

export default function OpenAPIList() {
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [openapis, setOpenapis] = useState<Openapi[]>([]);
  useEffect(() => {
    (async () => {
      const openapis = (await APIClient.openapi.list())
        .map(it => ({...it, isDeleting: false}));
      setOpenapis(openapis);
    })()
  }, [timestamp]);

  const columns = [
    {key: "name", name: "服务名称",},
    {key: "baseUrl", name: "服务地址",},
    {key: "createAt", name: "创建时间",},
    {key: "updateAt", name: "更新时间",},
    {key: "actions", name: "操作",}
  ];

  async function handleDelete(id: string) {
    const waiter = wait();
    try {
      setOpenapis(openapis.map(it => ({...it, isDeleting: it.id === id})));
      await APIClient.openapi.del(id);
      await waiter;
    } finally {
      setOpenapis(openapis.map(it => ({...it, isDeleting: false})));
      await waiter
      setTimestamp(new Date().getTime());
    }
  }

  const renderCell = (item: Openapi, columnKey: React.Key) => {
    switch (columnKey) {
      case "name":
        return (
          <TableCell><p className="truncate">{item.name}</p>
          </TableCell>
        );
      case "baseUrl":
        return (
          <TableCell><p className="truncate">{item.baseUrl}</p>
          </TableCell>
        );
      case "createAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(item.createAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "updateAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(item.updateAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "actions":
        return (
          <TableCell className="w-[180px]">
            <div className="flex justify-center space-x-2">
              <div>
                <Button variant="ghost" size="sm" color="primary"
                        onClick={() => {
                          setCurrentOpenapi(item)
                          onOpen();
                        }}
                >编辑</Button>
              </div>
              <div>
                <Button isLoading={item["isDeleting"]}
                        variant="ghost" size="sm" color="danger"
                        onClick={() => handleDelete(item.id)}>删除</Button>
              </div>
            </div>
          </TableCell>
        )

      default:
        return (<TableCell>...</TableCell>)
    }
  }

  const [currentOpenapi, setCurrentOpenapi] = useState<OpenapiUpsert | undefined>(undefined);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (<>
      <Modal placement="center" size="sm" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => <NewOpenapi openapi={currentOpenapi} onClose={() => {
            onClose();
            setTimestamp(new Date().getTime());
          }}/>}
        </ModalContent>
      </Modal>
      <Card>
        <CardHeader className="px-6 flex justify-between h-16">
          <div className="flex space-x-2">
          </div>
          <div className="flex items-center space-x-2">
            <Button variant="ghost" color="primary" size="sm"
                    onClick={() => {
                      setCurrentOpenapi(undefined);
                      onOpen();
                    }}
            >新增</Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table shadow="none" aria-label="OpenAPI列表">
            <TableHeader columns={columns}>
              {(column) => (
                <TableColumn
                  key={column.key}
                  className={classNames(
                    (column.key === "name" || column.key === "baseUrl") ? "" : "text-center"
                  )}
                >{column.name}</TableColumn>
              )}
            </TableHeader>
            <TableBody items={openapis}>
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => renderCell(item, columnKey)}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
