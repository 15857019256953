import {useNavigate, useParams,} from "react-router-dom";
import React, {useEffect, useState} from "react";
import APIClient, {Document} from "../../api";
import {Button, Card, CardBody, CardHeader, Input, ScrollShadow, Spacer, Textarea} from "@nextui-org/react";
import {GrMemory} from "react-icons/gr";
import {MdOutlineDescription, MdPublish} from "react-icons/md";
import {HiOutlineIdentification} from "react-icons/hi";
import Loading from "../../components/Loading";
import Markdown from "../../components/Markdown";
import styled from "styled-components";
import {useSearchParam} from "react-use";

const TextAreaWrapper = styled.div`
  flex: 1;

  > div {
    height: 100% !important;

    > div {
      height: 100% !important;

      > div {
        > textarea {
          height: 100% !important;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        > textarea::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`

export default function DocumentEditor() {
  const navigate = useNavigate();

  const params = useParams();
  const memoryProviderId = params.memoryProviderId!;
  const documentId = useSearchParam("id")
  const [timestamp,] = useState(new Date().getTime());

  const [document, setDocument] = useState<Document | undefined>(undefined)
  useEffect(() => {
    (async () => {
      if (documentId) {
        const document = await APIClient.document.get(memoryProviderId, documentId)
        setDocument(document)
      } else {
        setDocument({
          id: "",
          memoryProviderId: memoryProviderId,
          title: "",
          content: "",
          createAt: new Date(),
          updateAt: new Date(),
          memories: [],
        })
      }
    })()
  }, [documentId, timestamp, memoryProviderId]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit() {
    if (typeof document === "undefined") return;
    setIsSubmitting(true);
    try {
      await APIClient.document.upsert(
        document.memoryProviderId,
        document.title,
        document.content,
        document.id,
      )
      navigate(`/console/memory/${document.memoryProviderId}/documents`, {replace: true})
    } finally {
      setIsSubmitting(false)
    }
  }

  if (typeof document === "undefined")
    return <Loading/>

  return (
    <div className={"h-full flex *:flex-1 space-x-4"}>
      <div className="h-full">
        <Card className="h-full w-full flex flex-col">
          <CardHeader className="px-5 flex h-16 justify-between">
            <div className="flex items-center space-x-4">
              <GrMemory size={32}/>
              <div className="text-sm font-bold">编辑记忆</div>
            </div>
            <div>
              <Button
                isLoading={isSubmitting}
                endContent={<MdPublish size={18}/>}
                variant="ghost" color="primary" size="sm"
                onClick={handleSubmit}
              >发布</Button>
            </div>
          </CardHeader>
          <CardBody className="flex flex-col overflow-hidden">
            <Input size="sm" label="标题" endContent={<HiOutlineIdentification size={22}/>}
                   value={document.title}
                   onValueChange={(v) => setDocument({...document, title: v})}
            />
            <Spacer y={5}/>
            <TextAreaWrapper>
              <Textarea size="sm" label="内容" placeholder="请输入文章内容"
                        endContent={<MdOutlineDescription size={22}/>}
                        value={document.content} disableAutosize={true}
                        onValueChange={(v) => setDocument({...document, content: v})}
              />
            </TextAreaWrapper>
          </CardBody>
        </Card>
      </div>
      <div className="h-full">
        <Card className="h-full">
          <CardBody>
            <ScrollShadow hideScrollBar>
              <Markdown content={document.content}/>
            </ScrollShadow>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
