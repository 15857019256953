import {Button, Card, CardBody, CardFooter, CardHeader, Input, Spacer, Spinner} from "@nextui-org/react";
import {FaUser} from "react-icons/fa";
import {MdPassword} from "react-icons/md";
import React, {useState} from "react";
import {BsRobot} from "react-icons/bs";
import Tile from "react-parallax-tilt";
import APIClient from "../api";
import {useNavigate} from "react-router-dom";
import {useToken} from "../hooks/useToken";
import {useUser} from "../hooks/useUser";
import {useSearchParam} from "react-use";
import classNames from "classnames";
import {wait} from "../utils/waiter";

export default function Login() {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <Tile tiltMaxAngleX={5} tiltMaxAngleY={5} transitionSpeed={2000} scale={1.03}>
        <LoginComponent/>
      </Tile>
    </div>
  )
}

function LoginComponent() {
  const navigate = useNavigate();
  const returnUrl = useSearchParam("returnUrl");
  const [, setToken] = useToken();
  const [, setUser] = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  async function handleLogin() {
    const waiter = wait(2000);
    try {
      setIsLoading(true);
      setUser(undefined);
      const token = await APIClient.user.login(username, password, '');
      await waiter;
      setToken(token);
      if (returnUrl)
        navigate(decodeURIComponent(returnUrl), {replace: true});
      else
        navigate("/", {replace: true});
    } finally {
      await waiter;
      setIsLoading(false);
    }
  }

  return (
    <Card>
      <CardHeader className="pt-10 pb-4 flex-row justify-evenly items-center animate__animated animate__fadeInDown">
        <BsRobot className="animate__animated animate__fadeIn" size={54}/>
        <div>
          <h4 className="font-bold text-large animate__animated animate__flipInX">Jarvis</h4>
          <small className="text-default-500 uppercase animate__animated animate__fadeIn animate__delay-1s">
            AI for everyone!
          </small>
        </div>
      </CardHeader>
      <CardBody className="px-14 py-5 overflow-hidden">
        <Input size="md"
               className={
                 classNames(
                   "w-[280px] animate__animated",
                   isLoading ? "animate__fadeOutLeftBig" : "animate__fadeInLeft"
                 )
               }
               label="用户名" labelPlacement="outside" endContent={<FaUser/>}
               value={username} onValueChange={setUsername}
        />
        <Spacer y={3}/>
        <Input size="md" type="password" autoComplete="new-password"
               className={
                 classNames(
                   "w-[280px] animate__animated",
                   isLoading ? "animate__fadeOutRightBig" : "animate__fadeInRight"
                 )
               }
               label="密码" labelPlacement="outside" endContent={<MdPassword/>}
               value={password} onValueChange={setPassword}
        />
      </CardBody>
      <CardFooter className="flex justify-center p-4 pb-6 h-20 overflow-visible">
        <div style={{transition: `all 0.5s ease-out`, transform: isLoading ? `translateY(-80px)` : `translateY(0px)`}}>
          {
            isLoading ?
              <div className="flex flex-col justify-center animate__animated animate__fadeIn">
                <Spinner/>
                <div className="mt-4 text-sm font-bold">登陆中</div>
              </div>
              :
              <Button
                className="animate__animated animate__fadeInUp"
                color="primary" size="md" onClick={handleLogin}>登 陆</Button>
          }
        </div>
      </CardFooter>
    </Card>
  )
}
