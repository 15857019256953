import data from "@emoji-mart/data/sets/14/google.json"
import i18n from "@emoji-mart/data/i18n/zh.json"
import {init} from "emoji-mart"
import {Button, Modal, ModalContent, ModalFooter, useDisclosure} from "@nextui-org/react";
import React from "react";
import EmojiPicker from "@emoji-mart/react";
import styled from "styled-components";

init({data});

const PickerWrapper = styled.div`
  em-emoji-picker {
    width: 100%;
  }
`;

export default function Emoji(
  {
    picker = false,
    size = 32,
    icon,
    onIconChanged
  }: {
    picker?: boolean,
    icon: string,
    size?: number,
    onIconChanged?: (icon: string) => void
  }
) {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  // @ts-ignore
  const emoji = <em-emoji size={`${size}px`} shortcodes={icon} set="google"/>;
  const container = <div style={{height: `${size}px`, width: `${size}px`}}>{emoji}</div>;
  if (!picker)
    return container;

  return (
    <>
      <div onClick={onOpen} className="cursor-pointer">
        {container}
      </div>
      <Modal shouldBlockScroll={false} isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <PickerWrapper>
                <EmojiPicker data={data} set={"google"} i18n={i18n}
                             emojiSize={30} previewPosition={"none"}
                             dynamicWidth={true}
                             onEmojiSelect={icon => {
                               onIconChanged?.(icon.shortcodes);
                               onClose();
                             }}
                />
              </PickerWrapper>
              <ModalFooter>
                <Button variant="light" onPress={onClose}>
                  取消
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
