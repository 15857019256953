import APIClient from "../api";
import {wait} from "./waiter";
import {BinaryData, CompletedData, TextData, WebSocketEx} from "./websocket";
import {toast} from "react-hot-toast";
import Recorder from "js-audio-recorder";
import axios from "axios";
import {markdownPlainText} from "./markdown";

let currentAudioContext: AudioContext | undefined;
let currentAudioSourceInstance: { source?: AudioBufferSourceNode } | undefined;

export function ensureAudioCtx() {
  if (typeof currentAudioContext === "undefined")
    currentAudioContext = new AudioContext();
  if (currentAudioContext.state === "suspended")
    currentAudioContext.resume().catch(e => console.log("恢复AudioCtx失败:", e));
  return currentAudioContext;
}

export class AudioPlayer {
  private readonly onPlay: (sentence: string) => void;

  constructor(minLength: number = 15, maxLength: number = 50, onPlay: (sentence: string) => void) {
    this.sentenceLength = minLength;
    this.sentenceMaxLength = maxLength;
    this.onPlay = onPlay;
  }

  private waiter?: () => void;

  private releaseWaiter() {
    const waiter = this.waiter;
    this.waiter = undefined;
    waiter?.();
  }

  private static delimiter = ['\n', '。', '？', '！', '；'];
  private sentences = [] as string[];
  private currentSentence = "";
  private sentenceLength: number;
  private readonly sentenceMaxLength: number;

  feed(text: string) {
    if (this.isClosed)
      throw new Error("播放器已经关闭");
    const chars = text.split('');
    for (const char of chars) {
      this.currentSentence += char;
      if (this.currentSentence.length >= this.sentenceLength && AudioPlayer.delimiter.some(it => char === it)) {
        //放入整句缓存中
        this.sentences.push(this.currentSentence);
        //适当扩充后面的语句长度
        this.sentenceLength = Math.min(this.sentenceMaxLength, Math.max(this.currentSentence.length, this.sentenceLength));
        //清除缓存
        this.currentSentence = '';
      }
    }
    this.releaseWaiter();
  }

  private isFinish = false;

  finish() {
    this.isFinish = true;
    //处理最后一句
    if (this.currentSentence.length > 0) {
      this.sentences.push(this.currentSentence);
      this.currentSentence = '';
    }
    this.releaseWaiter();
  }

  private isClosed = false;

  close() {
    this.isClosed = true;
    this.abortController?.abort();
    AudioPlayer.audioStop();
    this.finish();
  }

  private isOnFlying = false;
  private lastPlaying?: Promise<boolean>;
  private abortController?: AbortController;

  async waitSilent() {
    do {
      while (this.isOnFlying) await wait(500);
      await this.lastPlaying;
    } while (this.isOnFlying)
  }

  async play() {
    while (!this.isClosed) {
      //取得一个句子
      const sentence = this.sentences.shift();
      //如果没有获取到
      if (typeof sentence === "undefined") {
        //判断是否已经没有数据了
        if (this.isFinish)
          break;
        //如果还有数据则注册一个waiter
        await new Promise<void>(resolve => this.waiter = resolve);
        //继续获取
        continue;
      }
      if (this.isClosed) return false;
      //如果句子为空白
      if (sentence.trim().length === 0)
        continue;
      //转换成语音
      try {
        this.isOnFlying = true;
        //处理sentence,处理掉链接和图片
        const plainText = markdownPlainText(sentence);
        if (plainText.length > 0) {
          this.abortController = new AbortController();
          const audio = await APIClient.tts(
            plainText,
            {
              voice: 'zh-CN-XiaoxiaoMultilingualNeural',
              signal: this.abortController.signal
            }
          );
          //如果上次的播放尚未完成,则需要等待
          if (typeof this.lastPlaying !== "undefined") {
            await this.lastPlaying;
            //播放前做一个停留
            if (this.isClosed) return false;
            await wait(150);
          }
          //开始本次播放
          if (this.isClosed) return false;
          this.lastPlaying = AudioPlayer.audioPlay(audio);
        }
        //回调
        this.onPlay(sentence);
      } catch (e) {
        if (axios.isCancel(e)) return false;
        throw e;
      } finally {
        this.isOnFlying = false;
      }
    }
    //等待最终播放完成
    return this.lastPlaying ?? false;
  }

  private static async audioPlay(data: ArrayBuffer) {
    try {
      //环境准备
      ensureAudioCtx();
      this.audioStop();
      //立刻占用实例
      const instance = {} as { source?: AudioBufferSourceNode };
      currentAudioSourceInstance = instance;
      //准备填充实例
      const audioContext = ensureAudioCtx();
      const buffer = await audioContext.decodeAudioData(data);
      //如果在准备buffer的时候,被其他的播放占用了,则直接返回false
      if (currentAudioSourceInstance !== instance) return false;
      //如果实例还没有被改变
      instance.source = audioContext.createBufferSource();
      instance.source.buffer = buffer;
      instance.source.connect(audioContext.destination);
      const playCompleted = new Promise<boolean>(resolve => {
        instance.source?.addEventListener("ended", () => {
          //如果source为undefined,则表示是通过stop暂停的,它并没有完整播放完毕
          resolve(typeof instance.source !== "undefined")
        })
      });
      instance.source.start();
      //等待播放完毕
      const completed = await playCompleted;
      //清理
      if (currentAudioSourceInstance === instance)
        currentAudioSourceInstance = undefined;
      return completed;
    } catch (e) {
      this.audioStop();
      return false;
    }
  }

  private static audioStop() {
    try {
      if (typeof currentAudioSourceInstance !== "undefined") {
        const source = currentAudioSourceInstance.source;
        //将source设置为undefined,表示被stop终止
        currentAudioSourceInstance.source = undefined;
        source?.stop();
      }
    } catch (e) {
    }
  }
}

//麦克风是否已经授权
let micOpened = false;

export async function ensureMicPhone() {
  if (micOpened) return;
  try {
    //判断是否有麦克风权限
    await (Recorder as any).getPermission();
    micOpened = true;
  } catch (e) {
    toast.error("无法获取麦克风,请检查您的设置");
  }
}

export class AudioRecorder {
  private readonly audioCtx: AudioContext;
  private readonly micStream: MediaStream;
  private readonly micSource: MediaStreamAudioSourceNode;
  private readonly processor: ScriptProcessorNode;
  private readonly onText: (text: string) => void;

  private websocket?: WebSocketEx;

  constructor(
    audioCtx: AudioContext,
    micStream: MediaStream,
    micSource: MediaStreamAudioSourceNode,
    processor: ScriptProcessorNode,
    onText: (text: string) => void,
  ) {
    this.audioCtx = audioCtx;
    this.micStream = micStream;
    this.micSource = micSource;
    this.processor = processor;
    this.onText = onText;
  }

  static async create(onText: (text: string) => void) {
    //确保用于麦克风权限
    await ensureMicPhone();
    //构建音频相关
    const audioCtx = ensureAudioCtx();
    const processor = audioCtx.createScriptProcessor(4096, 1, 1);
    //新建麦克风流
    const micStream = await navigator.mediaDevices.getUserMedia({audio: true});
    const micSource = audioCtx.createMediaStreamSource(micStream);
    return new AudioRecorder(audioCtx, micStream, micSource, processor, onText);
  }

  async start() {
    try {
      //组合初始化
      const cache = [] as BinaryData[];
      this.processor.onaudioprocess = async event => {
        const pcmF32 = event.inputBuffer.getChannelData(0);
        const pcm16bit = new Uint8Array(AudioRecorder.to16BitPCM(AudioRecorder.to16kHz(pcmF32)).buffer);
        //如果websocket尚未链接,则先放入到缓存中
        if (typeof this.websocket === "undefined") {
          cache.push(new BinaryData(pcm16bit));
          return;
        }
        //如果websocket已经连接,则先把缓存拉空
        while (cache.length > 0) {
          const data = cache.shift();
          if (typeof data === "undefined")
            break;
          await this.websocket.send(data);
        }
        //发送数据
        await this.websocket.send(new BinaryData(pcm16bit));
      };
      this.micSource.connect(this.processor);
      this.processor.connect(this.audioCtx.destination);
      //建立websocket连接
      this.websocket = await APIClient.stt();
      //开始接收数据
      let text = "";
      while (true) {
        const data = await this.websocket.recv();
        if (data instanceof TextData) {
          text = data.content;
          this.onText(text);
        }
        if (data instanceof CompletedData) {
          if (data.success)
            return text;
          throw new Error(data.message);
        }
      }
    } finally {
      await this.close();
    }
  }

  async close() {
    try {
      this.micStream.getTracks().forEach(s => s.stop());
      this.micSource.disconnect();
      this.processor.disconnect()
      await this.websocket?.send(new CompletedData(true, "完成"));
    } catch (_) {
    }
  }

  static to16kHz(data: Float32Array) {
    const fitCount = Math.round(data.length * (16000 / 44100))
    const newData = new Float32Array(fitCount)
    const springFactor = (data.length - 1) / (fitCount - 1)
    newData[0] = data[0]
    for (let i = 1; i < fitCount - 1; i++) {
      const tmp = i * springFactor
      const before = Math.floor(tmp);
      const after = Math.ceil(tmp);
      const atPoint = tmp - before;
      newData[i] = data[before] + (data[after] - data[before]) * atPoint
    }
    newData[fitCount - 1] = data[data.length - 1]
    return newData
  }

  static to16BitPCM(input: Float32Array) {
    const dataLength = input.length * (16 / 8)
    const dataBuffer = new ArrayBuffer(dataLength)
    const dataView = new DataView(dataBuffer)
    let offset = 0
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]))
      dataView.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }
    return dataView
  }
}
