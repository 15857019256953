import {Card, CardBody} from "@nextui-org/react";
import Emoji from "../../components/Emoji";
import React from "react";
import {useNavigate} from "react-router-dom";

function ActionBlock({icon, title, path}: { icon: string, title: string, path: string }) {
  const navigate = useNavigate();
  return (
    <Card shadow="sm" className="w-full h-48"
          isHoverable isPressable
          onClick={() => navigate(path)}
    >
      <CardBody className="h-full w-full flex justify-center items-center space-y-4">
        <Emoji icon={icon} size={48}/>
        <div className="text-sm font-bold">{title}</div>
      </CardBody>
    </Card>
  )
}

export default function ActionList() {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      <div className="animate__animated animate__fadeIn animate__faster"
           style={{animationDelay: "50ms"}}>
        <ActionBlock icon=":speaking_head_in_silhouette:" title="演说家" path="/console/action/presentation"/>
      </div>
      <div className="animate__animated animate__fadeIn animate__faster"
           style={{animationDelay: "100ms"}}>
        <ActionBlock icon=":bar_chart:" title="数据洞察" path="/console/action/database"/>
      </div>
      <div className="animate__animated animate__fadeIn animate__faster"
           style={{animationDelay: "150ms"}}>
        <ActionBlock icon=":nut_and_bolt:" title="OpenAPI" path="/console/action/openapi"/>
      </div>
      <div className="animate__animated animate__fadeIn animate__faster"
           style={{animationDelay: "200ms"}}>
        <ActionBlock icon=":wrench:" title="应用集成" path="/console/action/integrate"/>
      </div>
    </div>
  )
}
