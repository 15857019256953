import {getToken} from "../hooks/useToken";
import {CallData, CancelData, CompletedData, EventData, SubmitData, TextData, WebSocketEx} from "./websocket";

export class ChatSession {
  private websocket: WebSocketEx;

  private constructor(websocket: WebSocketEx) {
    this.websocket = websocket;
  }

  static async create(id: string) {
    const url = new URL(window.location.href);
    url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
    url.pathname = `/chat/${id}`
    url.search = `?token=${getToken()}`
    const websocket = await WebSocketEx.connect(url.toString());
    //等待连接成功
    const data = await websocket.recv();
    if (!(data instanceof CompletedData)) {
      throw new Error("协议错误");
    }
    if (!data.success) {
      throw new Error(data.message);
    }
    //连接成功
    return new ChatSession(websocket);
  }

  async run(
    message: string,
    onMessage: (text: string) => void,
    onEvent?: (event: EventData) => void,
    onCall?: (name: string, args: any) => Promise<any>,
  ) {
    await this.websocket.send(new TextData(message));
    while (true) {
      const data = await this.websocket.recv();
      //运行轮完成
      if (data instanceof CompletedData) {
        if (!data.success) throw new Error(data.message);
        return
      }
      //文本数据
      if (data instanceof TextData) {
        onMessage(data.content);
      } else if (data instanceof EventData) {
        onEvent?.(data);
      } else if (data instanceof CallData) {
        if (typeof onCall === "undefined") {
          await this.websocket.send(new SubmitData(data.id, "函数不存在"));
          return;
        }
        onCall(data.name, data.arguments)
          .then(result => this.websocket.send(new SubmitData(data.id, result)))
          .catch(error => this.websocket.send(new SubmitData(data.id, error.toString())));
      } else {
        //TODO:其他消息处理
        console.log(data);
      }
    }
  }

  async cancel() {
    await this.websocket.send(new CancelData("用户取消"));
  }

  async close() {
    await this.websocket.close();
  }
}
