import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import APIClient, {Presentation} from "../../../api";
import {wait} from "../../../utils/waiter";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Input,
  Modal,
  ModalContent,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure
} from "@nextui-org/react";
import dayjs from "dayjs";
import {FaCircleCheck} from "react-icons/fa6";
import {CgSearchLoading} from "react-icons/cg";
import {FaSearch} from "react-icons/fa";
import classNames from "classnames";
import {HiOutlineIdentification} from "react-icons/hi";
import {VscSymbolNamespace} from "react-icons/vsc";

function FileInput(
  {
    placeholder, value, onValueChange
  }: {
    placeholder: string,
    value?: File,
    onValueChange: (file?: File) => void,
  }
) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Button color="primary"
            onClick={() => inputRef.current?.click()}>
      <div className="overflow-hidden text-ellipsis">{value ? value.name : placeholder}</div>
      <input ref={inputRef} type="file" accept=".ppt,.pptx" hidden
             onChange={e => {
               const file = e.target.files?.[0];
               onValueChange(file);
             }}
      />
    </Button>
  );
}

function NewPresentation({onClose}: { onClose: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0.0);

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [landscapeFile, setLandscapeFile] = useState<File>();
  const [portraitFile, setPortraitFile] = useState<File>();

  async function handleSubmit() {
    try {
      setIsLoading(true);
      await APIClient.presentation.upsert(
        {name, title, landscape: landscapeFile, portrait: portraitFile},
        event => setProgress(event.progress ?? 0.0)
      )
      onClose();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">创建演说家</div>
        {name && <Chip color="secondary" size="sm">{name}</Chip>}
      </CardHeader>
      <CardBody className="p-5">
        <Input label="标题" labelPlacement="outside"
               endContent={<HiOutlineIdentification className="text-amber-500" size={22}/>}
               value={title} onValueChange={setTitle}
        />
        <Spacer y={2}/>
        <Input label="调用名" labelPlacement="outside"
               endContent={<VscSymbolNamespace className="text-primary" size={22}/>}
               value={name} onValueChange={setName}
        />
        <Spacer y={5}/>
        <div className="flex *:flex-1 space-x-4">
          <FileInput placeholder="选择横屏文件" value={landscapeFile} onValueChange={setLandscapeFile}/>
          <FileInput placeholder="选择竖屏文件" value={portraitFile} onValueChange={setPortraitFile}/>
        </div>
      </CardBody>
      <CardFooter className="px-5">
        <Button className="w-full" variant="bordered" color="secondary"
                isLoading={isLoading} onClick={handleSubmit}>
          {isLoading ? `正在处理 (${(progress * 100).toFixed()}%)` : "上传文档"}
        </Button>
      </CardFooter>
    </Card>
  )
}

export default function PresentationList() {
  const navigate = useNavigate();

  const [presentations, setPresentations] = useState<Presentation[]>([]);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [keyword, setKeyword] = useState('');
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  //定时刷新索引中的数据
  useEffect(() => {
    const interval = setInterval(() => {
      if (presentations.find(it => !it.indexed)) {
        setTimestamp(new Date().getTime());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [presentations]);

  useEffect(() => {
    (async () => {
      const presentations = (await APIClient.presentation.list())
        .filter(it => it.title.includes(keyword))
        .map(it => ({...it, isDeleting: false}));
      setPresentations(presentations)
    })()
  }, [keyword, timestamp]);

  const columns = [
    {key: "name", name: "调用名"},
    {key: "title", name: "标题",},
    {key: "createAt", name: "创建时间",},
    {key: "updateAt", name: "更新时间",},
    {key: "indexed", name: "索引状态",},
    {key: "actions", name: "操作",}
  ]

  async function handleDelete(presentationId: string) {
    const waiter = wait();
    try {
      setPresentations(presentations.map(it => ({...it, isDeleting: it.id === presentationId})));
      await APIClient.presentation.del(presentationId);
      await waiter;
    } finally {
      setPresentations(presentations.map(it => ({...it, isDeleting: false})));
      await waiter
      setTimestamp(new Date().getTime());
    }
  }

  const renderCell = (presentation: Presentation, columnKey: React.Key) => {
    switch (columnKey) {
      case "name":
        return (
          <TableCell><p className="text-center font-bold">{presentation.name}</p>
          </TableCell>)
      case "title":
        return (
          <TableCell><p className="truncate">{presentation.title}</p>
          </TableCell>)
      case "createAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(presentation.createAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "updateAt":
        return (<TableCell
          className="text-center w-[180px] min-w-[150px]">{dayjs(presentation.updateAt).format("YYYY-MM-DD HH:mm")}</TableCell>)
      case "indexed":
        return (
          <TableCell className="text-center w-[120px]">
            <Chip size="sm" variant="flat" color={presentation.indexed ? 'success' : 'warning'}
                  startContent={presentation.indexed ? <FaCircleCheck size={18}/> : <CgSearchLoading size={18}/>}>
              {presentation.indexed ? '索引完成' : '索引中'}
            </Chip>
          </TableCell>
        );
      case "actions":
        return (
          <TableCell className="w-[180px]">
            <div className="flex justify-center space-x-2">
              <div>
                <Button variant="ghost" size="sm" color="primary" isDisabled={!presentation.indexed}
                        onClick={() => navigate(`/console/action/presentation/${presentation.id}`)}>
                  编辑
                </Button>
              </div>
              <div>
                <Button isLoading={presentation["isDeleting"]}
                        variant="ghost" size="sm" color="danger"
                        onClick={() => handleDelete(presentation.id)}>删除</Button>
              </div>
            </div>
          </TableCell>
        )
      default:
        return (<TableCell>...</TableCell>)
    }
  };

  return (
    <>
      <Modal placement="center" size="sm"
             isOpen={isOpen} onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => <NewPresentation onClose={() => {
            onClose();
            setTimestamp(new Date().getTime());
          }}/>}
        </ModalContent>
      </Modal>
      <Card>
        <CardHeader className="px-6 flex justify-between h-16">
          <div className="flex space-x-2">
            <Input variant="bordered" size="sm"
                   labelPlacement={"outside-left"}
                   placeholder="搜索"
                   isClearable
                   startContent={<FaSearch/>}
                   className="max-w-xs"
                   value={keyword}
                   onValueChange={setKeyword}
                   onClear={() => setKeyword('')}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Button variant="ghost" color="primary" size="sm"
                    onClick={onOpen}
            >
              新增
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table shadow="none" aria-label="演说家列表">
            <TableHeader columns={columns}>
              {(column) => (
                <TableColumn
                  key={column.key}
                  className={classNames(
                    column.key === "title" ? "" : "text-center"
                  )}
                >{column.name}</TableColumn>
              )}
            </TableHeader>
            <TableBody items={presentations}>
              {(item) => (
                <TableRow key={item.id}>
                  {(columnKey) => renderCell(item, columnKey)}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
