import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";

const TokenContext = createContext(getToken());
let tokenSetter: Dispatch<SetStateAction<string | undefined>>;

export default function TokenProvider({children}: { children: ReactNode }) {
  const [token, setToken] = useState(getToken());
  tokenSetter = setToken;
  return (
    <TokenContext.Provider value={token}>
      {children}
    </TokenContext.Provider>
  )
}

export function useToken() {
  const context = useContext(TokenContext);
  return [context, setToken] as const;
}

export function setToken(token?: string) {
  token ? localStorage.setItem("TOKEN", token) : localStorage.removeItem("TOKEN");
  tokenSetter(token)
}

export function getToken() {
  return localStorage.getItem("TOKEN") ?? undefined;
}
