import React, {useEffect, useState} from "react";
import APIClient, {PresentationResp} from "../../../api";
import Loading from "../../../components/Loading";
import {useParams} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Chip, Pagination, Textarea} from "@nextui-org/react";
import Emoji from "../../../components/Emoji";
import styled from "styled-components";
import {FaPlay, FaSave} from "react-icons/fa";
import {wait} from "../../../utils/waiter";
import PresentationDisplay from "../../../components/PresentationDisplay";
import {IoIosTabletLandscape, IoIosTabletPortrait} from "react-icons/io";

const TextAreaWrapper = styled.div`
  flex: 1;

  > div {
    height: 100% !important;

    > div {
      height: 100% !important;

      > div {
        > textarea {
          height: 100% !important;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        > textarea::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`

export default function PresentationEditor() {
  const params = useParams();

  const [presentation, setPresentation] = useState<PresentationResp>();
  useEffect(() => {
    (async () => {
      if (params.id) {
        const presentation = await APIClient.presentation.get(params.id);
        setPresentation(presentation);
        if (presentation.portrait) setPortrait(true);
        if (presentation.landscape) setPortrait(false);
      }
    })()
  }, [params.id]);

  const [portrait, setPortrait] = useState(false);
  const [slideNo, setSlideNo] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [preview, setPreview] = useState(false);

  async function handleSubmit() {
    const waiter = wait();
    try {
      setIsSubmitting(true);
      const slide = presentation?.slides[slideNo];
      if (typeof slide === "undefined")
        throw new Error("找不到幻灯片")
      await APIClient.presentation.updateCommentary(slide.id, slide.commentary)
    } finally {
      await waiter;
      setIsSubmitting(false);
    }
  }

  if (typeof presentation === "undefined")
    return <Loading/>;

  return (
    <>
      {preview && (
        <div className="h-screen w-screen fixed top-0 left-0 z-[100000]">
          <div className="h-full w-full bg-gray-100 absolute top-0 left-0 opacity-95"></div>
          <PresentationDisplay id={params.id!} pageNo={slideNo + 1} rotationLock={portrait ? 'portrait' : 'landscape'}
                               onCompleted={() => setPreview(false)}/>
        </div>
      )}
      <Card className="h-full w-full">
        <CardHeader className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Emoji size={38} icon=":speaking_head_in_silhouette:"/>
            <div className="font-bold">{presentation.title}</div>
            <Chip variant="dot" color="primary" size="sm">{presentation.name}</Chip>
          </div>
          <div className="flex items-center space-x-4">
            {presentation.landscape && presentation.portrait &&
              <Button size="sm" color={portrait ? "secondary" : "primary"}
                      startContent={portrait ? <IoIosTabletLandscape size={18}/> : <IoIosTabletPortrait size={18}/>}
                      onClick={() => setPortrait(v => !v)}>
                切换到{portrait ? "横屏" : "竖屏"}
              </Button>
            }
            <Button size="sm" color="primary" startContent={<FaPlay/>}
                    onClick={() => setPreview(true)}>
              预览
            </Button>
            <div className="text-sm font-bold">幻灯片页面</div>
            <Pagination
              showControls siblings={1} boundaries={0}
              size="sm"
              total={presentation.slides.length} initialPage={slideNo + 1}
              onChange={it => setSlideNo(it - 1)}
            />
          </div>
        </CardHeader>
        <CardBody className="flex justify-center items-center relative">
          <img className="max-w-full max-h-full" alt={`slide-${slideNo}`}
               src={`/object/${portrait ? presentation.slides[slideNo].portrait : presentation.slides[slideNo].landscape}`}/>
          <div className="absolute bottom-0 right-0 drop-shadow">
            <div className="w-[400px]">
              <TextAreaWrapper>
                <Textarea placeholder="请填入幻灯片解说词" color="primary"
                          minRows={3} maxRows={8}
                          endContent={
                            <Button isIconOnly size="sm" color="primary"
                                    isLoading={isSubmitting} onClick={handleSubmit}
                            >
                              <FaSave/>
                            </Button>
                          }
                          value={presentation.slides[slideNo].commentary}
                          onValueChange={text => {
                            setPresentation({
                              ...presentation,
                              slides: presentation.slides.map(
                                it =>
                                  it.index === slideNo
                                    ? {...it, commentary: text}
                                    : it
                              )
                            });
                          }}
                />
              </TextAreaWrapper>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
