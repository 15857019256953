import React, {useEffect, useMemo, useState} from "react";
import APIClient, {PresentationSlide} from "../api";
import {AnimatePresence, motion} from "framer-motion";
import Loading from "./Loading";
import {CgClose} from "react-icons/cg";
import classNames from "classnames";
import {AudioPlayer} from "../utils/audio";
import {useImmer} from "use-immer";
import {useResponsive} from "ahooks";

export default function PresentationDisplay({id, pageNo = 1, play = true, rotationLock, onCompleted}: {
  id: string,
  pageNo?: number,
  play?: boolean,
  rotationLock?: 'landscape' | 'portrait',
  onCompleted?: () => void
}) {
  const responsiveInfo = useResponsive();
  const isMobile = useMemo(
    () => {
      if (typeof rotationLock !== "undefined")
        return rotationLock === "portrait";
      return responsiveInfo.xs && !responsiveInfo.sm;
    },
    [rotationLock, responsiveInfo.xs, responsiveInfo.sm]
  );
  const [slides, setSlides] = useImmer<PresentationSlide[] | undefined>(undefined);
  const [slideNo, setSlideNo] = useState(pageNo - 1);

  //加载幻灯片数据
  useEffect(() => {
    (async () => {
      const presentation = await APIClient.presentation.get(id);
      setSlides(presentation.slides);
    })()
  }, [id, setSlides]);

  //已经播放完毕了
  useEffect(() => {
    if (typeof slides !== "undefined" && slideNo >= slides.length)
      onCompleted?.();
  }, [slides, slideNo, onCompleted]);

  const currentSlide = useMemo(() => {
    if (typeof slides === "undefined") return;
    return slides[slideNo];
  }, [slides, slideNo]);

  const currentAudio = useMemo(() => {
    if (!play) return;
    if (typeof currentSlide === "undefined") return;
    return currentSlide
  }, [currentSlide, play]);

  const nextSlide = useMemo(() => {
    if (typeof slides === "undefined") return;
    return slides[slideNo + 1];
  }, [slides, slideNo])

  //播放音频
  //eslint-disable-next-line
  const currentAudioMemo = useMemo(() => currentAudio, [currentAudio?.id]);
  const [subtitle, setSubtitle] = useState<string>();

  //播放当前幻灯片
  useEffect(() => {
    setSubtitle(undefined);
    if (typeof currentAudioMemo === "undefined") return;
    //没有文字内容,3s后直接下一页
    if (currentAudioMemo.commentary.trim().length === 0) {
      const timer = setTimeout(() => setSlideNo(currentAudioMemo.index + 1), 3000);
      return () => clearTimeout(timer);
    }
    //有解说词,开始播放解说词
    const player = new AudioPlayer(5, 20, sentence => {
      setSubtitle(sentence);
    });
    player.feed(currentAudioMemo.commentary);
    player.finish();
    player.play().then(completed => {
      if (completed)
        setSlideNo(currentAudioMemo.index + 1);
    });
    return () => {
      setSubtitle(undefined);
      player.close();
    };
  }, [currentAudioMemo]);

  if (typeof slides === "undefined" || typeof currentSlide === "undefined")
    return <Loading/>;

  return (
    <div className="h-full w-full relative">
      {nextSlide && (
        <img style={{width: 0, height: 0}}
             src={`/object/${isMobile ? (nextSlide.portrait ?? nextSlide.landscape) : (nextSlide.landscape ?? nextSlide.portrait)}`}
             alt={`slide-${nextSlide.id}`}
        />
      )}
      <div
        className={classNames(
          "absolute top-2 right-2 z-[2]",
          "bg-gray-100 opacity-60",
          "p-2 rounded-[100px] shadow",
          "hover:shadow hover:opacity-100 cursor-pointer",
          "transition-all duration-500 ease-in-out"
        )}
        onClick={onCompleted}
      >
        <CgClose size={32}/>
      </div>
      {subtitle && (
        <div className="absolute bottom-5 sm:bottom-10 left-0 w-full flex justify-center items-center z-10">
          <div className={classNames("relative px-4 py-2 mx-20")}>
            <div className="bg-black h-full w-full absolute top-0 left-0 shadow rounded-xl"/>
            <div className="relative z-1 text-sm sm:text-xl text-white">{subtitle}</div>
          </div>
        </div>
      )}
      {
        slides.map((slide) => (
          <div key={slide.id} className="absolute top-0 left-0 h-full w-full">
            <AnimatePresence>
              {
                slide.index === slideNo && (
                  <motion.div
                    className="h-full w-full flex justify-center items-center"
                    initial={{opacity: 0, translateY: 100, zIndex: 1}}
                    animate={{opacity: 1, translateY: 0, zIndex: 0}}
                    exit={{opacity: 0, translateY: 0, zIndex: -1}}
                    transition={{duration: 1, type: "spring", bounce: 0.3}}>
                    <img className="max-h-full max-w-full" alt={`slide-${slide.id}`}
                         src={`/object/${isMobile ? (slide.portrait ?? slide.landscape) : (slide.landscape ?? slide.portrait)}`}/>
                  </motion.div>
                )
              }
            </AnimatePresence>
          </div>
        ))
      }
    </div>
  );
}
