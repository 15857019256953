import axios, {AxiosError, type AxiosInstance, AxiosProgressEvent, type AxiosRequestConfig} from "axios";
import {jsonParse} from "../utils/json";
import {APIAuthenticationError, APICommonError, APIError} from "./error";
import type {
  Agent,
  AgentUpsert,
  Document,
  FunctionGroup,
  Integrate,
  IntegrateUpsert,
  Memory,
  MemoryUpsert,
  Openapi,
  OpenapiUpsert,
  Presentation,
  PresentationResp,
  User
} from "./model";
import {getToken} from "../hooks/useToken";
import {CompletedData, WebSocketEx} from "../utils/websocket";

export class Client {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      transformResponse: (data: any, _, status) => {
        if (status && status <= 400) {
          return jsonParse(data)
        }
        return null
      }
    })
    this.axiosInstance.interceptors.request.use((config: any) => {
      config.headers["TOKEN"] = getToken()
      return config;
    })
  }

  async post<T>(url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<T> {
    try {
      const resp = await this.axiosInstance.post<{ success: boolean, message: string, data: T }>(url, data, config);
      if (resp.data.success) {
        return resp.data.data;
      } else { //通用异常. 直接显示
        throw new APICommonError(resp.data.message);
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e?.response?.status === 403) { //跳转登录
          throw new APIAuthenticationError();
        } else {
          throw new APIError(-1, e.message, e);
        }
      } else if (e instanceof APIError) {
        throw e;
      } else {
        throw new APIError(-1, e.message || '未知错误', e);
      }
    }
  }

  tts = async (
    text: string,
    options: {
      voice?: string,
      signal?: AbortSignal
    }
  ) => {
    const resp = await axios.post(
      '/tts',
      {text, voice: options.voice},
      {
        responseType: 'arraybuffer',
        headers: {'TOKEN': getToken()},
        signal: options.signal
      },
    );
    if (resp.status === 204)
      return new ArrayBuffer(0);
    if (resp.status !== 200)
      throw new Error("无法语音合成,错误状态码:" + resp.status);
    return resp.data as ArrayBuffer;
  };

  stt = async () => {
    //建立websocket连接
    const url = new URL(window.location.href);
    url.protocol = url.protocol === "https:" ? "wss:" : "ws:";
    url.pathname = `/stt`
    url.search = `?token=${getToken()}`
    //连接到websocket
    const websocket = await WebSocketEx.connect(url.toString());
    //等待成功
    const initSuccessData = await websocket.recv();
    if (!(initSuccessData instanceof CompletedData))
      throw new Error("语音识别发生错误");
    if (!initSuccessData.success)
      throw new Error("云端识别启动失败");
    //返回websocket
    return websocket;
  }

  /** 用户相关 */
  user = {
    temporary: (name: string) => this.post<{ id: string, authenticate: string }>("/user/temporary", {name}),
    get: (id: string) => this.post<User>(`/user/${id}`),
    list: () => this.post<User[]>("/user/list"),
    current: () => this.post<User>("/user/current"),
    login: (Username: string, Password: string, Authenticate: string) => this.post<string>("/user/auth", {
      Authenticate,
      Username,
      Password
    }),
    logout: () => this.post("/user/logout"),
  }

  memory = {
    list: () => this.post<Memory[]>(`/memory/list`),
    del: (id: string) => this.post(`/memory/delete/${id}`),
    upsert: (memory: MemoryUpsert) => this.post<string>(`/memory/upsert`, memory),
    get: (id: string) => this.post<Memory>(`/memory/get/${id}`),
  }

  document = {
    list: (memoryProviderId: string, keyword: string) => this.post<Document[]>(
      `/memory/${memoryProviderId}/document/list`,
      {keyword,}
    ),
    del: (memoryProviderId: string, id: string) => this.post(`/memory/${memoryProviderId}/document/delete/${id}`),
    get: (memoryProviderId: string, id: string) => this.post<Document>(`/memory/${memoryProviderId}/document/get/${id}`),
    upsert: (memoryProviderId: string, title: string, content: string, id: string) => this.post<string>(
      `/memory/${memoryProviderId}/document/upsert`, {
        title,
        content,
        id,
      }
    ),
  }

  agent = {
    upsert: (agent: AgentUpsert) => this.post<string>(`/agent/upsert`, agent),
    del: (id: string) => this.post(`/agent/delete/${id}`),
    list: () => this.post<Agent[]>(`/agent/list`),
    get: (id: string) => this.post<Agent>(`/agent/get/${id}`),
    models: () => this.post<string[]>(`/agent/models`)
  }

  func = {
    list: () => this.post<FunctionGroup[]>(`/function/list`),
  }

  presentation = {
    upsert: async (
      presentation: { id?: string, name: string, title: string, landscape?: File, portrait?: File },
      onProgress?: (progressEvent: AxiosProgressEvent) => void,
    ) => {
      const formData = new FormData();
      presentation.id && formData.append("id", presentation.id);
      formData.append("name", presentation.name);
      formData.append("title", presentation.title);
      presentation.landscape && formData.append("landscape", presentation.landscape);
      presentation.portrait && formData.append("portrait", presentation.portrait);
      //上传
      return this.post<string>(
        `/presentation/upsert`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: onProgress
        }
      );
    },
    del: (id: string) => this.post(`/presentation/delete/${id}`),
    list: () => this.post<Presentation[]>(`/presentation/list`),
    get: (id: string) => this.post<PresentationResp>(`/presentation/get/${id}`),
    updateCommentary: (id: string, commentary: string) => this.post(`/presentation/slide/update`, {id, commentary}),
  }

  openapi = {
    list: () => this.post<Openapi[]>(`/openapi/list`),
    upsert: (openapi: OpenapiUpsert) => this.post(`/openapi/upsert`, openapi),
    del: (id: string) => this.post(`/openapi/delete/${id}`),
  }

  integrate = {
    upsert: (integrate: IntegrateUpsert) => this.post(`/integrate/upsert`, integrate),
    del: (id: string) => this.post(`/integrate/delete/${id}`),
    list: () => this.post<Integrate[]>(`/integrate/list`)
  }
}
