export interface PagedResult<T> {
  totalPage: number
  pageSize: number
  pageNo: number
  total: number
  data: T[]
}

export enum Role {
  Admin = 'Admin',
  User = 'User',
  Temporary = "Temporary",
}

export interface User {
  id: string,
  name: string,
  role: Role,
  authenticate: string,
}

export interface MemoryUpsert {
  id?: string;
  icon: string;
  name: string;
}

export interface Memory extends MemoryUpsert {
  id: string;
  createAt: Date;
  updateAt: Date;
}

export interface Document {
  id: string;
  title: string;
  content: string;
  memoryProviderId: string;
  memories: string[] | null;
  createAt: Date;
  updateAt: Date;
}

export interface FunctionDesc {
  name: string;
  description: string;
}

export interface FunctionGroup {
  name: string,
  functions: FunctionDesc[]
}

export interface AgentUpsert {
  id?: string;
  model?: string;
  icon: string,
  name: string;
  title: string;
  description: string;
  instructions: string;
  memoryProviders: string[];
}

export interface Agent extends AgentUpsert {
  id: string;
  model: string;
  baseOnCtx: boolean;
  presentationMemory: boolean;
  textAudioSync: boolean;
  prologue: boolean;
  createAt: Date;
  updateAt: Date;
}

export interface Presentation {
  id: string;
  name: string;
  title: string;
  landscape?: string;
  portrait?: boolean;
  indexed: boolean;
  createAt: Date;
  updateAt: Date;
}

export interface PresentationSlide {
  id: string;
  presentationId: string;
  index: number;
  landscape?: string;
  portrait?: string;
  commentary: string;
  createAt: Date;
  updateAt: Date;
}

export interface PresentationResp extends Presentation {
  slides: Array<PresentationSlide>
}

export interface OpenapiUpsert {
  id?: string;
  name: string;
  config: string;
}

export interface Openapi extends OpenapiUpsert {
  id: string;
  baseUrl: string;
  createAt: Date;
  updateAt: Date;
}

export interface IntegrateUpsert {
  id?: string;
  name: string;
  key: string;
}

export interface Integrate extends IntegrateUpsert {
  id: string;
  createAt: Date;
  updateAt: Date;
}
