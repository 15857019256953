import React, {useEffect, useState} from "react";
import APIClient, {Role, User} from "../api";
import Loading from "./Loading";
import {Autocomplete, AutocompleteItem, Button} from "@nextui-org/react";
import {QRCodeSVG} from "qrcode.react";
import {wait} from "../utils/waiter";
import classNames from "classnames";

export default function AgentShare({agentId}: { agentId: string }) {
  const [users, setUsers] = useState<User[]>();
  useEffect(() => {
    (async () => {
      const users = await APIClient.user.list();
      setUsers(users.filter(it => it.role === Role.Temporary))
    })()
  }, []);

  const [user, setUser] = useState<User>();
  const [userName, setUserName] = useState('');

  const [isCreating, setIsCreating] = useState(false);

  async function handleCreate() {
    const waiter = wait();
    try {
      setIsCreating(true);
      const {id} = await APIClient.user.temporary(userName);
      const users = await APIClient.user.list();
      const user = await APIClient.user.get(id);
      await waiter;
      setUsers(users.filter(it => it.role === Role.Temporary));
      setUser(user);
    } finally {
      setIsCreating(false)
    }
  }

  if (typeof users === "undefined")
    return <Loading/>;

  return (
    <div>
      <Autocomplete
        allowsCustomValue
        size="sm"
        label="选择目标用户或者立刻创建一个"
        defaultItems={users}
        selectedKey={user?.id}
        onInputChange={setUserName}
        onSelectionChange={key => setUser(users?.find(it => it.id === key))}
      >
        {(user) => <AutocompleteItem key={user.id}>{user.name}</AutocompleteItem>}
      </Autocomplete>
      <div className="h-80 p-6 flex justify-center items-center">
        {typeof user === "undefined" && userName === ""
          ? <div className="text-sm font-bold">请选择你需要分享的用户</div>
          : (typeof user === "undefined"
              ? <Button isLoading={isCreating} size="sm" color="primary"
                        onClick={handleCreate}>
                创建临时用户并分享
              </Button>
              : <div className="h-full w-full flex flex-col space-y-4">
                <div className="flex-1 overflow-hidden">
                  <QRCodeSVG className="h-full w-full"
                             value={`${window.location.protocol}//${window.location.host}/chat/${user.authenticate}/${agentId}`}
                  />
                </div>
                <div className={classNames(
                  "text-sm text-primary text-wrap break-all",
                  "shadow-md rounded-lg border-2 border-primary px-2.5 py-1"
                )}>
                  {`${window.location.protocol}//${window.location.host}/chat/${user.authenticate}/${agentId}`}
                </div>
              </div>
          )
        }
      </div>
    </div>
  )
}
