import React, {useEffect, useState} from "react";
import {MdOutlineDesignServices} from "react-icons/md";
import {FaCode, FaMusic} from "react-icons/fa6";
import {SiWritedotas} from "react-icons/si";
import {VscBeaker} from "react-icons/vsc";
import {GrUserManager} from "react-icons/gr";
import {useNavigate} from "react-router-dom";
import {BsRobot} from "react-icons/bs";
import {AnimatePresence, motion} from "framer-motion";
import {Button} from "@nextui-org/react";


export default function Home() {
  const roles = [
    {name: "product manager", color: "text-primary", icon: <MdOutlineDesignServices size={24}/>},
    {name: "programmer", color: "text-secondary", icon: <FaCode size={20}/>},
    {name: "writer", color: "text-teal-500", icon: <SiWritedotas size={20}/>},
    {name: "scientist", color: "text-amber-500", icon: <VscBeaker size={22}/>},
    {name: "artist", color: "text-fuchsia-500", icon: <FaMusic size={20}/>},
    {name: "officer", color: "text-danger-500", icon: <GrUserManager size={22}/>},
  ]

  const navigate = useNavigate();
  const [roleIndex, setRoleIndex] = useState<number>(0);
  useEffect(() => {
    const timer = setInterval(() => setRoleIndex(roleIndex => (roleIndex + 1) % roles.length), 4000);
    return () => clearInterval(timer);
  }, [roles.length]);

  return (
    <div className="h-full w-full">
      <div className="h-full w-full flex flex-col justify-center items-center uppercase">
        <div className="flex items-center space-x-4">
          <div className="relative">
            <BsRobot size={64}/>
            {
              roles.map((role, index) => (
                <div key={role.name} className="absolute -top-1 -right-3">
                  <AnimatePresence>
                    {roleIndex === index && (
                      <motion.div className={`${roles[roleIndex].color}`}
                                  initial={{opacity: 0, translateY: -10, zIndex: 1}}
                                  animate={{opacity: 1, translateY: 0, zIndex: 0}}
                                  exit={{opacity: 0, translateY: 0, zIndex: -1}}
                                  transition={{duration: 0.8, type: "spring", bounce: 0.25}}>
                        {roles[roleIndex].icon}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))
            }
          </div>
          <div className="text-5xl font-bold">Jarvis</div>
        </div>
        <div className="mt-2 text-center font-bold">AI for</div>
        <div className="mt-2 h-12 w-full relative text-6xl font-bold">
          {
            roles.map((role, index) => (
              <div key={role.name} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <AnimatePresence>
                  {roleIndex === index && (
                    <motion.div key={roleIndex}
                                className={`${roles[roleIndex].color} drop-shadow-2xl`}
                                initial={{opacity: 0, translateY: 50, zIndex: 1}}
                                animate={{opacity: 1, translateY: 0, zIndex: 0}}
                                exit={{opacity: 0, translateY: -10, zIndex: -1}}
                                transition={{duration: 0.8, type: "spring", bounce: 0.25}}
                    >
                      {roles[roleIndex].name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))
          }
        </div>
        <div className="mt-12 flex items-center space-x-4">
          <Button color="primary" variant="shadow" onClick={() => navigate("/console")}>开始</Button>
        </div>
      </div>
    </div>
  )
}
