import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import {User} from "../api";

const UserContext = createContext<User | null | undefined>(undefined);
let userSetter: Dispatch<SetStateAction<User | null | undefined>>;

export default function UserProvider({children}: { children: ReactNode }) {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  userSetter = setUser;
  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  const context = useContext(UserContext);
  return [context, userSetter] as const;
}
