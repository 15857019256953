import {
  $convertToMentionNodes,
  BeautifulMentionComponentProps,
  BeautifulMentionsItemData,
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsPlugin,
  createBeautifulMentionNode,
  ZERO_WIDTH_CHARACTER,
  ZeroWidthNode,
  ZeroWidthPlugin,
} from "lexical-beautiful-mentions";
import React, {createContext, forwardRef, useContext, useEffect, useState} from "react";
import classNames from "classnames";
import {LuBrainCircuit} from "react-icons/lu";
import {BiError} from "react-icons/bi";
import {$createParagraphNode, $getRoot, EditorState, LexicalEditor} from "lexical";
import APIClient from "../api";
import Loading from "./Loading";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {PlainTextPlugin} from "@lexical/react/LexicalPlainTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {VscTools} from "react-icons/vsc";

interface MentionData {
  [p: string]: BeautifulMentionsItemData,

  isAgent: boolean

  value: string
  id: string
  display: string
  description: string
  groupName: string | null
}

const MentionMenuComponent = forwardRef<HTMLElement, BeautifulMentionsMenuProps>(
  ({children}, ref) => {
    return (
      <div ref={ref as any}
           className={classNames(
             "mt-6 max-w-96 max-h-48 relative z-[99999]",
             "bg-white rounded-xl shadow-lg border",
             "overflow-y-auto no-scrollbar"
           )}>
        {children}
      </div>
    )
  });

const MentionMenuItemComponent = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
  ({selected, item}, ref) => {
    const icon = item.data?.isAgent
      ? <LuBrainCircuit className="text-primary" size={18}/>
      : <VscTools className="text-amber-500" size={18}/>;
    return (
      <div ref={ref as any}
           className={classNames(
             "p-2 flex items-center space-x-2 overflow-hidden",
             selected && "bg-gray-100"
           )}>
        <div className={classNames(
          "border p-2 bg-white rounded-3xl",
          selected && "shadow"
        )}>
          {icon}
        </div>
        <div className="overflow-hidden">
          <div className={classNames("text-sm", selected && "font-bold")}>{item.data?.display}</div>
          <div className="text-[12px] text-gray-500 whitespace-nowrap">
            {item.data?.description}
          </div>
        </div>
      </div>
    )
  }
);

const MentionDataListContext = createContext<MentionData[]>([]);

const MentionComponent = forwardRef<HTMLDivElement, BeautifulMentionComponentProps<MentionData>>(
  ({data, value}, ref) => {
    const mentionDataList = useContext(MentionDataListContext);
    //如果不存在,则尝试查找
    let mentionData = data ?? mentionDataList.find(d => d.value === value);
    //渲染节点
    const bgColor = typeof mentionData === "undefined"
      ? "bg-danger-500"
      : (mentionData.isAgent
          ? "bg-primary-500"
          : "bg-amber-500"
      );
    const icon = typeof mentionData === "undefined"
      ? <BiError size={13}/>
      : (mentionData.isAgent
          ? <LuBrainCircuit size={13}/>
          : <VscTools size={13}/>
      );
    return (
      <div ref={ref} className={classNames(
        "rounded-lg inline-flex items-center space-x-1 text-white",
        "px-2 py-[4px] m-0",
        bgColor
      )}>
        {icon}
        <div className="text-[10px] font-bold">
          {mentionData?.display ?? `不存在: ${value}`}
        </div>
      </div>
    );
  }
);

export default function InstructionsEditor({agentId, instructions, onInstructionsChanged}: {
  agentId: string,
  instructions: string,
  onInstructionsChanged: (instructions: string) => void
}) {
  //提示数据
  const [mentionDataList, setMentionDataList] = useState<MentionData[]>();
  useEffect(() => {
    (async () => {
      const agents = await APIClient.agent.list();
      const functionGroups = await APIClient.func.list();
      const mentionDataList: MentionData[] = [
        ...agents.map(a => ({
          isAgent: true,
          value: a.name,
          id: a.id,
          display: a.title,
          description: a.description,
          groupName: null,
        })),
        ...functionGroups.flatMap(g => g.functions.map(f => ({
          isAgent: false,
          value: f.name,
          id: f.name,
          display: f.name,
          description: f.description,
          groupName: g.name,
        })))
      ];
      setMentionDataList(mentionDataList);
    })()
  }, [setMentionDataList])

  //如果提示数据还没有加载完全,则返回加载页面
  if (typeof mentionDataList === "undefined")
    return <Loading/>

  function setEditorState(initialValue: string, triggers: string[]) {
    return () => {
      const root = $getRoot();
      if (root.getFirstChild() === null) {
        const paragraph = $createParagraphNode();
        paragraph.append(...$convertToMentionNodes(initialValue, triggers, "@"));
        root.append(paragraph);
      }
    };
  }

  function handleEditorChanged(_: EditorState, editor: LexicalEditor) {
    const parsedEditorState = editor.parseEditorState(JSON.stringify(editor.getEditorState().toJSON()));
    const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent());
    onInstructionsChanged(editorStateTextString)
  }

  async function handleSearch(_: string, query: string | null | undefined) {
    query = query?.toLowerCase();
    return mentionDataList!
      .filter(m => !m.isAgent || m.id !== agentId)
      .filter(m => {
        if (!query)
          return true
        return m.value.toLowerCase().includes(query)
          || m.display.toLowerCase().includes(query)
          || m.description.toLowerCase().includes(query)
      });
  }

  return (
    <MentionDataListContext.Provider value={mentionDataList}>
      <LexicalComposer
        initialConfig={{
          namespace: "Instructions",
          onError: (error: Error) => console.error(error),
          nodes: [...createBeautifulMentionNode(MentionComponent), ZeroWidthNode],
          editorState: setEditorState(instructions, ["@"]),
        }}
      >
        <PlainTextPlugin
          contentEditable={<ContentEditable
            className="h-full border-2 border-primary-400 rounded-lg p-2 overflow-y-auto"/>}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}/>
        <HistoryPlugin/>
        <OnChangePlugin onChange={handleEditorChanged}/>
        <ZeroWidthPlugin textContent={ZERO_WIDTH_CHARACTER}/>
        <BeautifulMentionsPlugin
          triggers={["@"]}
          menuComponent={MentionMenuComponent}
          menuItemComponent={MentionMenuItemComponent}
          onSearch={handleSearch}
          allowSpaces={false}
          punctuation={"@"}
          menuItemLimit={false}
        />
      </LexicalComposer>
    </MentionDataListContext.Provider>
  )
}
