import React, {ReactNode, useEffect, useRef, useState} from "react";
import {wait} from "../utils/waiter";
import {toast} from "react-hot-toast";
import classNames from "classnames";
import {BsChat, BsRobot} from "react-icons/bs";
import {Button, Spacer, Spinner, Textarea} from "@nextui-org/react";
import {useUser} from "../hooks/useUser";
import ScrollView from "./ScrollView";
import {BiUser} from "react-icons/bi";
import Markdown from "./Markdown";
import APIClient, {Agent, Presentation} from "../api";
import PresentationDisplay from "./PresentationDisplay";
import {AudioPlayer, AudioRecorder, ensureAudioCtx, ensureMicPhone} from "../utils/audio";
import {useImmer} from "use-immer";
import {RiKeyboardFill} from "react-icons/ri";
import {FiSend} from "react-icons/fi";
import {FaCircleStop, FaMicrophone, FaStop} from "react-icons/fa6";
import {ChatSession} from "../utils/chat";
import {useKeyPress} from "ahooks";
import styled from "styled-components";

function useChatSession(agentId: string) {
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  const [connecting, setConnecting] = useState(true);

  //初始化chat session
  const sessionRef = useRef<{ instance?: ChatSession }>({});
  useEffect(() => {
    //先放入一个空的实例引用
    const sessionInstance: { instance?: ChatSession } = {};
    sessionRef.current = sessionInstance;
    //然后再填充它
    const newSessionPromise = (async () => {
      const waiter = wait(500);
      try {
        setConnecting(true);
        const agent = await APIClient.agent.get(agentId);
        setAgent(agent);
        const newSession = await ChatSession.create(agentId);
        await waiter
        sessionInstance.instance = newSession
        return newSession;
      } catch (e: any) {
        toast.error(e?.message);
      } finally {
        setConnecting(false);
      }
    })();
    return () => {
      newSessionPromise.then(s => s?.close())
    }
  }, [agentId]);

  return [connecting, agent, sessionRef] as const;
}

function usePresentation(children: ReactNode) {
  const [presentations, setPresentations] = useState<Presentation[]>();
  const [currentPresentation, setCurrentPresentation] = useState<{
    id: string,
    play: boolean,
    onSubmitResult: (result: any) => void
  }>();
  useEffect(() => {
    (async () => {
      const presentations = await APIClient.presentation.list();
      setPresentations(presentations);
    })()
  }, []);
  useKeyPress(['space'], () => {
    if (currentPresentation?.play) {
      currentPresentation.onSubmitResult(`演讲再次被用户打断.请明确询问用户"您是有什么疑问吗?没有的话我是否可以继续进行演讲?",不要说其他任何内容.`);
      setCurrentPresentation({
        ...currentPresentation,
        play: false
      });
    }
  });

  const ui = currentPresentation
    ? (
      <div className="h-screen w-screen fixed top-0 left-0 z-[1000]">
        {currentPresentation.play
          ? (
            <div
              className={classNames(
                "absolute bottom-2 right-2 z-[1001]",
                "bg-secondary",
                "p-4 rounded-[100px] shadow-md shadow-secondary cursor-pointer",
              )}
              onClick={() => {
                if (currentPresentation) {
                  currentPresentation.onSubmitResult(`演讲再次被用户打断.请明确询问用户"您是有什么疑问吗?没有的话我是否可以继续进行演讲?",不要说其他任何内容.`);
                  setCurrentPresentation({
                    ...currentPresentation,
                    play: false
                  });
                }
              }}
            >
              <BsChat color="white" size={28}/>
            </div>
          )
          : (
            <div
              className={classNames(
                //手机屏幕
                "bottom-0 right-0 w-screen",
                //其他屏幕
                "sm:bottom-5 sm:right-5 sm:w-[450px]",
                "absolute z-[100001] h-1/2",
                "bg-gray-50",
                "p-4 rounded-xl sm:border border-gray-400 sm:shadow-md shadow-gray-400",
                "animate__animated animate__fadeInRight animate__faster"
              )}
            >
              {children}
            </div>
          )
        }
        <div className="h-full w-full bg-gray-100 absolute top-0 left-0"></div>
        <PresentationDisplay id={currentPresentation.id}
                             play={currentPresentation.play}
                             onCompleted={() => {
                               currentPresentation?.onSubmitResult("演讲已经完成,可以让用户针对不明白的地方提问.")
                               setCurrentPresentation(undefined);
                             }}/>
      </div>
    )
    : undefined;

  return [presentations, currentPresentation, setCurrentPresentation, ui] as const;
}

const TextAreaWrapper = styled.div`
  flex: 1;

  *:focus-visible {
  }

  > div {

    > div {
      box-shadow: none !important;

      > div {
        > textarea {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        > textarea::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`

export default function Chat({agentId}: { agentId: string }) {
  const [user] = useUser();

  const [connecting, agent, sessionRef] = useChatSession(agentId);
  const [running, setRunning] = useState(false);
  const [text, setText] = useState('');
  const [messages, setMessages] = useImmer<{ role: "assistant" | "user", name: string, content: string }[]>([])
  const [typing, setTyping] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => setTyping(v => !v), 1000);
    return () => clearInterval(timer);
  }, []);

  //语音相关
  const [audioMode, setAudioMode] = useState(false);
  const [sttStatus, setSttStatus] = useState<"ready" | "starting" | "listening">("ready");
  const [sttText, setSttText] = useState<string>();
  const playerRef = useRef<AudioPlayer>();
  const recorderRef = useRef<AudioRecorder>();
  useEffect(() => {
    playerRef.current?.close();
    recorderRef.current?.close();
  }, []);

  const chatUI = (
    <div className="h-full flex flex-col space-y-2">
      <div className="flex-1 px-0 sm:px-4 overflow-hidden">
        <ScrollView>
          {messages.map((message, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center space-x-2 mb-[2px]">
                {message.role === "assistant"
                  ? <BsRobot size={18}/>
                  : <BiUser size={18}/>
                }
                <div className="text-[14px] font-bold">{message.name}</div>
              </div>
              <div className="ml-[26px]">
                {message.role === "assistant"
                  ? <Markdown content={
                    message.content
                    + ((index === messages.length - 1 && running)
                      ? (typing ? '■' : '□')
                      : '')
                  }/>
                  : <pre className="text-[14px]">{message.content}</pre>
                }
              </div>
            </div>
          ))}
          {typeof sttText !== "undefined" && (
            <div className="mb-4">
              <div className="flex items-center space-x-2 mb-[2px]">
                <BiUser size={18}/>
                <div className="text-[14px] font-bold">{user?.name ?? "你"}</div>
              </div>
              <div className="ml-[26px]">
                <pre className="text-[14px]">{sttText + (typing ? '■' : '□')}</pre>
              </div>
            </div>
          )}
          <Spacer y={6}/>
        </ScrollView>
      </div>
      <div className="flex items-center space-x-4">
        <Button isIconOnly color={audioMode ? "primary" : "secondary"} variant="flat"
                className="!outline-none"
                onClick={async () => {
                  await ensureMicPhone();
                  setAudioMode(it => !it);
                }}>
          {!audioMode
            ? <FaMicrophone size={22}/>
            : <RiKeyboardFill size={22}/>
          }
        </Button>
        <div className="flex-1 flex items-center space-x-4">
          {audioMode
            ? (
              <Button className="w-full animate__animated animate__fadeIn" color="secondary"
                      isLoading={sttStatus === "starting"}
                      onClick={async () => {
                        if (running)
                          await handleCancel();
                        else
                          await handleRecord();
                      }}
              >
                {
                  (() => {
                    if (running)
                      return <><FaCircleStop size={20}/>AI处理中, 点击提前终止</>;
                    switch (sttStatus) {
                      case "ready":
                        return <><FaMicrophone size={20}/>点击说话</>
                      case "starting":
                        return "正在启动,请稍等"
                      case "listening":
                        return <><FaCircleStop size={20}/>聆听中, 点击提前终止</>
                    }
                  })()
                }
              </Button>
            )
            : (
              <>
                <TextAreaWrapper>
                  <Textarea className="animate__animated animate__fadeIn"
                            color="primary" variant="faded"
                            minRows={1} maxRows={5}
                            placeholder="伟大的创造从这里开始..."
                            onKeyDown={async event => {
                              if (!event.shiftKey && event.key === "Enter") {
                                event.preventDefault();
                                await handleSubmit()
                              }
                            }}
                            value={text} onValueChange={setText}/>
                </TextAreaWrapper>
                <Button className="animate__animated animate__fadeIn !outline-none"
                        style={{animationDelay: "100ms"}}
                        isIconOnly color="primary"
                        onClick={async () => {
                          if (running)
                            await handleCancel();
                          else
                            await handleSubmit();
                        }}
                >
                  {running
                    ? <FaStop size={20}/>
                    : <FiSend size={20}/>
                  }
                </Button>
              </>
            )
          }
        </div>
      </div>
    </div>
  );

  //演讲相关
  const [
    presentations,
    currentPresentation,
    setCurrentPresentation,
    presentationUI
  ] = usePresentation(chatUI);

  async function handleSubmit(input?: string) {
    if (running) return false;
    setRunning(true);
    try {
      ensureAudioCtx();
      const textEx = input ?? text.trim();
      if (textEx === "") return false;
      setText("");
      const currentSession = sessionRef.current.instance;
      if (typeof currentSession === "undefined") return false;
      setMessages(messages => {
        messages.push({
          role: "user",
          name: user?.name ?? "你",
          content: textEx
        });
      });
      setMessages(messages => {
        messages.push({
          role: "assistant",
          name: agent?.title!,
          content: ''

        });
      });
      //构造一个播放器
      const player = new AudioPlayer(15, 50, sentence => setMessages(messages => {
        messages[messages.length - 1].content += sentence;
      }));
      playerRef.current = player;
      //启动播放器
      let playCompleted = Promise.resolve(true);
      if (audioMode)
        playCompleted = player.play();
      //启动session
      await currentSession.run(
        textEx,
        char => {
          if (audioMode)
            player.feed(char);
          if (!audioMode)
            setMessages(messages => {
              messages[messages.length - 1].content += char;
            });
        },
        event => {
          console.log(event);
        },
        async (name, args) => {
          //等待播放器静下来
          await player.waitSilent();
          //判断是否为演说家调用
          const presentation = presentations?.find(it => name === `start_${it.name}`);
          if (presentation) {
            return new Promise(resolve => {
              //启动/恢复一个幻灯片的播放
              const id = currentPresentation?.id === presentation.id ? currentPresentation.id : presentation.id;
              setCurrentPresentation({
                id,
                play: true,
                onSubmitResult: resolve
              });
            });
          }
          throw new Error("函数不存在");
        }
      );
      //session运行完毕,再也没有文本数据传入了
      player.finish();
      //等待播放完成
      await playCompleted;
      return true;
    } finally {
      setRunning(false);
    }
  }

  async function handleCancel() {
    sessionRef.current.instance?.cancel();
    playerRef.current?.close();
  }

  async function handleRecord() {
    let currentRecorder = recorderRef.current;

    //正在录音,直接关闭就好了
    if (typeof currentRecorder !== "undefined") {
      recorderRef.current = undefined;
      await currentRecorder.close();
      return;
    }

    //启动一个新的录音
    try {
      //启动录音
      setSttStatus("starting");
      currentRecorder = await AudioRecorder.create(text => {
        setSttText(text);
      });
      recorderRef.current = currentRecorder;
      setSttStatus("listening");
      setSttText("");
      const text = await currentRecorder.start();
      setSttText(undefined);
      //开始交互
      handleSubmit(text).catch(e => console.error("启动交互失败:", e));
    } finally {
      setSttText(undefined);
      recorderRef.current?.close();
      recorderRef.current = undefined;
      setSttStatus("ready");
    }
  }

  //正在连接中
  if (connecting)
    return (
      <div className={classNames(
        "h-full w-full",
        "flex flex-col justify-center items-center space-y-2"
      )}>
        <BsRobot size={45}/>
        <Spinner color="current" size="sm"/>
      </div>
    );

  //演讲视图
  if (presentationUI)
    return presentationUI

  //直接返回聊天UI
  return chatUI;
}
