import {useEffect, useRef} from "react";
import {useSize} from "ahooks";

export default function ScrollView({children}) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const {width, height} = useSize(scrollElementRef) ?? {width: 0, height: 0};
  useEffect(() => {
    const scrollContainerElement = scrollContainerRef.current;
    if (scrollContainerElement !== null) {
      scrollContainerElement.scrollTop = scrollContainerElement.scrollHeight;
    }
  }, [width, height]);

  return (
    <div ref={scrollContainerRef} className="h-full no-scrollbar overflow-x-hidden overflow-y-auto">
      <div ref={scrollElementRef}>
        {children}
      </div>
    </div>
  )
}
