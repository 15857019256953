import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Input,
  Modal,
  ModalContent,
  Select,
  SelectItem,
  Spacer,
  Textarea,
  useDisclosure
} from "@nextui-org/react";
import {useList} from "react-use";
import React, {useEffect, useState} from "react";
import APIClient, {Agent} from "../../api";
import {FaPlus} from "react-icons/fa";
import {MdOutlineDescription} from "react-icons/md";
import {VscSymbolNamespace} from "react-icons/vsc";
import {HiOutlineIdentification} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {wait} from "../../utils/waiter";
import Emoji from "../../components/Emoji";
import classNames from "classnames";
import {FaInfinity} from "react-icons/fa6";

function AgentBlock({agent, onDelete}: { agent: Agent, onDelete: () => void }) {
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    const waiter = wait();
    try {
      setIsDeleting(true);
      await APIClient.agent.del(agent.id!);
      await waiter;
      onDelete();
    } finally {
      await waiter;
      setIsDeleting(false);
    }
  }

  return (
    <Card shadow="sm" className="w-full h-48">
      <CardHeader className="p-4 flex justify-between">
        <Emoji icon={agent.icon}/>
        <div className="text-sm font-bold">{agent.title}</div>
        <Chip color="secondary" size="sm">{agent.name}</Chip>
      </CardHeader>
      <CardBody className="p-4">
        <div className="text-[13px]">
          {agent.description}
        </div>
      </CardBody>
      <CardFooter className="flex justify-between items-center">
        <div>
          {agent.memoryProviders.length > 0
            && (
              <div className="bg-secondary rounded-lg px-[5px] py-1">
                <div className="flex items-center space-x-1">
                  <div className="text-[12px] text-white">记忆体</div>
                  <div className={classNames(
                    "h-5 min-w-5 flex justify-center items-center",
                    "bg-white rounded-md text-[12px] text-secondary"
                  )}>
                    {agent.memoryProviders.length}
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="flex items-center space-x-2">
          <Button variant="ghost" color="primary" size="sm"
                  onClick={() => navigate(`/console/agent/${agent.id}`)}>设置</Button>
          <Button variant="ghost" isLoading={isDeleting} color="danger" size="sm" onClick={handleDelete}>删除</Button>
        </div>
      </CardFooter>
    </Card>
  )
}

function NewAgent({onClose}: { onClose: () => void }) {
  const navigate = useNavigate();

  const [models, setModels] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      const models = await APIClient.agent.models();
      setModels(models)
    })()
  }, []);

  const [model, setModel] = useState<string>();
  const [icon, setIcon] = useState(':robot_face:');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  async function handleCreate() {
    const waiter = wait();
    try {
      setIsLoading(true);
      const id = await APIClient.agent.upsert({
        model, icon, name, title, description,
        instructions: '', memoryProviders: [],
      });
      await waiter;
      navigate(`/console/agent/${id}`);
    } finally {
      await waiter;
      setIsLoading(false);
    }
  }

  return (
    <Card className="w-full">
      <CardHeader className="p-5 flex justify-between">
        <div className="text-medium font-bold">创建智能体</div>
        {name && <Chip color="secondary" size="sm">{name}</Chip>}
      </CardHeader>
      <CardBody className="p-5">
        <div className="flex items-center space-x-4">
          <Emoji size={36} picker icon={icon} onIconChanged={setIcon}/>
          <Input size="sm" label="友好名称" endContent={<HiOutlineIdentification className="text-amber-500" size={22}/>}
                 value={title} onValueChange={setTitle}
          />
        </div>
        <Spacer y={2}/>
        <Input label="调用名" labelPlacement="outside"
               endContent={<VscSymbolNamespace className="text-primary" size={22}/>}
               value={name} onValueChange={setName}
        />
        <Spacer y={2}/>
        <Select size="sm" label="模型" labelPlacement="outside" placeholder="请选择LLM模型"
                endContent={<FaInfinity className="text-secondary" size={22}/>}
                defaultSelectedKeys={models.length > 0 ? models[0] : undefined}
                onSelectionChange={(selection) => {
                  selection = selection as Set<string>
                  const selected = Array.from(selection.values()).map(it => it.toString());
                  if (selected.length > 0)
                    setModel(selected[0]);
                }}
        >
          {models.map(m => <SelectItem key={m} value={m}>{m}</SelectItem>)}
        </Select>
        <Spacer y={8}/>
        <Textarea label="描述" placeholder="一个很有用的智能体"
                  endContent={<MdOutlineDescription className="text-cyan-600" size={22}/>}
                  minRows={2} maxRows={3}
                  value={description} onValueChange={setDescription}
        />
      </CardBody>
      <CardFooter className="p-5 space-x-2 flex justify-end">
        <Button size="sm" color="primary" isLoading={isLoading} onClick={handleCreate}>
          {isLoading ? '创建中' : '创建'}
        </Button>
        <Button size="sm" color="danger" onClick={onClose}>取消</Button>
      </CardFooter>
    </Card>
  )
}

export default function AgentList() {
  const [agents, setAgents] = useList<Agent>();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  useEffect(() => {
    (async () => {
      const agents = await APIClient.agent.list();
      setAgents.set(agents);
    })()
  }, [setAgents, timestamp]);

  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  return (
    <>
      <Modal shouldBlockScroll={false} placement="center" size="sm"
             isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => <NewAgent onClose={onClose}/>}
        </ModalContent>
      </Modal>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        <Card className="w-full h-48 flex justify-center items-center animate__animated animate__fadeIn"
              isPressable isHoverable
              shadow="sm"
              onClick={onOpen}>
          <FaPlus size={32}/>
        </Card>
        {
          agents.map((a, index) => (
            <div key={a.id} className="animate__animated animate__fadeIn animate__faster"
                 style={{animationDelay: `${index * 50}ms`}}>
              <AgentBlock agent={a} onDelete={() => setTimestamp(new Date().getTime())}/>
            </div>
          ))
        }
      </div>
    </>
  )
}
