import {createBrowserRouter, Navigate, Outlet, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import APIClient, {Role} from "./api";
import {setToken, useToken} from "./hooks/useToken";
import {useUser} from "./hooks/useUser";
import {BsRobot} from "react-icons/bs";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import AgentList from "./pages/agent/AgentList";
import MemoryList from "./pages/memory/MemoryList";
import ActionList from "./pages/action/ActionList";
import AgentEditor from "./pages/agent/AgentEditor";
import {wait} from "./utils/waiter";
import DocumentList from "./pages/memory/DocumentList";
import DocumentEditor from "./pages/memory/DocumentEditor";
import Home from "./pages/Home";
import Chat from "./pages/Chat";
import PresentationList from "./pages/action/presentation/PresentationList";
import DatabaseList from "./pages/action/database/DatabaseList";
import OpenAPIList from "./pages/action/openapi/OpenAPIList";
import IntegrateList from "./pages/action/integrate/IntegrateList";
import PresentationEditor from "./pages/action/presentation/PresentationEditor";

//路由保护组件
function RouteProtect() {
  const location = useLocation();
  const [token] = useToken();
  const [user, setUser] = useUser();
  useEffect(() => {
    (async () => {
      try {
        const waiter = wait(500);
        const user = await APIClient.user.current();
        await waiter;
        setUser(user);
      } catch (e) {
        setToken(undefined);
        setUser(null);
      }
    })();
  }, [token, setUser]);
  //判断是否授权
  const returnUrl = encodeURIComponent(`${location.pathname}${location.search}`);
  //token都没有,直接GG
  if (typeof token === "undefined")
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  //user还没有获取到,等一等
  if (typeof user === "undefined")
    return (
      <div className="h-full flex justify-center items-center">
        <BsRobot size={54}/>
      </div>
    )
  //user已经获取到,但是不存在,直接前往登陆页面
  if (user === null)
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  //如果用户不是管理员用户
  if (user.role !== Role.Admin)
    return <Navigate to={`/login?returnUrl=${returnUrl}`} replace/>;
  //正常路由
  return <Outlet/>
}

export const router = createBrowserRouter([
  {
    index: true,
    element: <Home/>
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/chat/:authenticate/:agentId",
    element: <Chat/>
  },
  {
    path: "/console",
    element: <RouteProtect/>,
    children: [
      {
        element: <Layout/>,
        children: [
          {
            index: true,
            element: <Navigate to="agent" replace/>
          },
          {
            path: "agent",
            children: [
              {
                index: true,
                element: <AgentList/>
              },
              {
                path: ":agentId",
                element: <AgentEditor/>
              }
            ]
          },
          {
            path: "memory",
            children: [
              {
                index: true,
                element: <MemoryList/>
              },
              {
                path: ":memoryProviderId",
                children: [
                  {
                    path: "documents",
                    children: [
                      {
                        index: true,
                        element: <DocumentList/>
                      },
                      {
                        path: "editor",
                        element: <DocumentEditor/>
                      }
                    ],
                  },
                ]
              }
            ]
          },
          {
            path: "action",
            children: [
              {
                index: true,
                element: <ActionList/>
              },
              {
                path: "presentation",
                children: [
                  {
                    index: true,
                    element: <PresentationList/>
                  },
                  {
                    path: ":id",
                    element: <PresentationEditor/>
                  }
                ]
              },
              {
                path: "database",
                element: <DatabaseList/>
              },
              {
                path: "openapi",
                element: <OpenAPIList/>
              },
              {
                path: "integrate",
                element: <IntegrateList/>
              }
            ]
          }
        ]
      }
    ]
  },
]);
